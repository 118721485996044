import { gql } from 'graphql-request';

const engageIncentives = gql`
  query($subdomain: String!) {
    engageIncentives(subdomain: $subdomain) {
      engage1 {
        id
        titleAr
        titleEn
        staticId
        descriptionEn
        descriptionAr
      }
      engage2 {
        id
        titleAr
        titleEn
        staticId
        descriptionEn
        descriptionAr
      }
      engage3 {
        id
        titleAr
        titleEn
        staticId
        descriptionEn
        descriptionAr
      }
      engageFees
    }
  }
`;

export default engageIncentives;
