import React, { useContext } from 'react';
import { Typography, useTheme } from '@zydalabs/zac-react';

import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import { ReactComponent as DeliveryIcon } from 'assets/delivery-icon.svg';
import { ReactComponent as VerdIcon } from 'assets/verd-icon.svg';

const StoreRiderInfo = ({ assignedDriver, order, reassignButton }) => {
  const { colors } = useTheme();
  const { translate } = useContext(localeContext);
  const { selectedStore } = useContext(userContext);
  const { verdFees } = order || {};

  const { currency } = selectedStore;

  return (
    <div className="flex flex-col w-full border-t border-gray-200 mt-2 pt-4">
      <div className="flex gap-2">
        <div className="w-full flex justify-between">
          <div className="flex gap-2">
            <div className="mt-1">
              <DeliveryIcon fill={colors.positive.secondary} />
            </div>
            <div className="flex flex-col gap-1">
              <Typography variant="element16">{assignedDriver?.name}</Typography>
              <Typography variant="element14" color={colors.positive.secondary}>
                {assignedDriver?.phone || ''}
              </Typography>
            </div>
          </div>
          {reassignButton}
        </div>
      </div>
      {!!verdFees && (
        <div className="w-full flex gap-2 border-t border-gray-200 pt-4 mt-4">
          <div className="mt-1">
            <VerdIcon fill={colors.positive.tertiary} />
          </div>
          <div>
            <Typography>{`${translate(currency)} ${verdFees?.toFixed(currency.decimals)} `}</Typography>
            <Typography variant="element14" color={colors.positive.secondary}>
              Processing Fees
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
};

export default StoreRiderInfo;
