import { gql } from 'graphql-request';

const engageCurrentSettings = gql`
  query($subdomain: String!) {
    currentEngageSettings(subdomain: $subdomain) {
      engage1Settings {
        descriptionEn
        descriptionAr
        titleEn
        titleAr
        amount
        type
      }
      engage2Settings {
        descriptionEn
        descriptionAr
        titleEn
        titleAr
        amount
        type
      }
      engage3Settings {
        descriptionEn
        descriptionAr
        titleEn
        titleAr
        amount
        type
      }
      engageFees
    }
  }
`;

export default engageCurrentSettings;
