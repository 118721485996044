import React from 'react';
import * as yup from 'yup';
import { FlashIcon } from '@zydalabs/zac-icons-react';
import cx from 'classnames';

import { Text } from 'components/service';
import { Tag } from 'components/kit';
import {
  ORDERFAST_WALLET,
  INSTANT,
  ORDERFAST_WALLET_DISCLAMER,
  CREDIT_CARD,
  REFUND_STATMENT,
  TAX_PERCENTAGE_ERR,
  ORDERFAST_WALLET_AND_CREDIT_CARD,
  REFUND_TO_BOTH_WALLET_AND_CREDIT_CARD,
} from 'constants/translations';
import { REFUND_TYPE_ENUM } from '../Table/Order/OrderDetails/CancelOrderButton/CancelOrderBody/data';
import { PAYMENT_METHODS_ENUMS } from '../Frame/utils';
import RefundCompensationStepper from './RefundCompensationStepper';

export const refundRadioListItems = (
  orderPaidThrough,
  lang,
  handleSettingCompensationAmount,
  currency,
  orderTotal,
  orderfastWalletRefundData,
) => {
  const refundOptions = [];
  const walletRefundOption = {
    value: REFUND_TYPE_ENUM.ORDERFAST_WALLET,
    body: (
      <div className={cx('flex flex-row', lang === 'en' ? 'float-right' : 'float-left flex-row-reverse')}>
        <Text value={ORDERFAST_WALLET} className={lang === 'en' ? 'mr-2 ' : 'ml:2'} />
        <Tag
          lang={lang}
          icon={<FlashIcon color="#B48A00" width="20px" height="20px" />}
          labelCss="px-0 py-0 text-zyda-yellow-150"
          baseCss="bg-zyda-yellow-25"
          title={<Text value={INSTANT} />}
          refund
        />
      </div>
    ),
    subTitle: (
      <div>
        <Text value={ORDERFAST_WALLET_DISCLAMER} />
        {orderfastWalletRefundData.refundType === REFUND_TYPE_ENUM.ORDERFAST_WALLET && (
          <RefundCompensationStepper
            handleSettingCompensationAmount={handleSettingCompensationAmount}
            currency={currency}
            orderTotal={orderTotal}
          />
        )}
      </div>
    ),
    className: 'text-xs text-black',
  };
  const creditCartRefundOption = {
    value: REFUND_TYPE_ENUM.CREDIT_CARD,
    body: (
      <div className={cx('flex flex-row', lang === 'en' ? 'float-right' : 'float-left flex-row-reverse')}>
        <Text value={CREDIT_CARD} className={lang === 'en' ? 'mr-2 ' : 'ml:2'} />
      </div>
    ),
    subTitle: <Text value={REFUND_STATMENT} />,
    className: 'text-xs text-black',
  };

  const walletAndCreditCardOption = {
    value: REFUND_TYPE_ENUM.WALLET_AND_CREDIT_CARD,
    body: (
      <div className={cx('flex flex-row', lang === 'en' ? 'float-right' : 'float-left flex-row-reverse')}>
        <Text value={ORDERFAST_WALLET_AND_CREDIT_CARD} className={lang === 'en' ? 'mr-2 ' : 'ml:2'} />
      </div>
    ),
    subTitle: (
      <div>
        <Text value={REFUND_TO_BOTH_WALLET_AND_CREDIT_CARD} />
        {orderfastWalletRefundData.refundType === REFUND_TYPE_ENUM.WALLET_AND_CREDIT_CARD && (
          <RefundCompensationStepper
            handleSettingCompensationAmount={handleSettingCompensationAmount}
            currency={currency}
            orderTotal={orderTotal}
          />
        )}
      </div>
    ),

    className: 'text-xs text-black',
  };

  refundOptions.push(walletRefundOption);

  if (orderPaidThrough !== PAYMENT_METHODS_ENUMS.WALLET) {
    if (orderPaidThrough === PAYMENT_METHODS_ENUMS.WALLET_AND_CREDIT_CARD) {
      refundOptions.push(walletAndCreditCardOption);
    } else {
      refundOptions.push(creditCartRefundOption);
    }
  }

  return refundOptions;
};

export const compensatedAmountValidationSchema = yup.object().shape({
  compensatedAmount: yup.number().max(100, <Text value={TAX_PERCENTAGE_ERR} />),
});
