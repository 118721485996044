import React, { useContext, useEffect } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Spinner, Typography, useTheme } from '@zydalabs/zac-react';

import * as translations from 'constants/translations';
import { COURIER_ERROR_CODE } from 'constants/courier';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import { ShimmerCircle } from 'components/kit';

const StoreCourier = ({ courier, deliveryCourierId, selected, setSelected, isCouriersEstimationsLoading }) => {
  const { lang, translate } = useContext(localeContext);
  const {
    selectedStore: { currency },
  } = useContext(userContext);
  const { colors } = useTheme();

  const { courierDetails, courierEstimations, courierId: id, logoUrl, inNetwork } = courier || {};
  const { displayNameEn: courierNameEn, displayNameAr: courierNameAr } = courierDetails || {};
  const { pickupTime, success, assignable, deliveryTime, deliveryFees, errorCode } = courierEstimations || {};

  const courierId = Number(id);
  const isArabic = lang === 'ar';
  const isCourierSelected = selected?.courierId === id;

  useEffect(() => {
    if (courierId === deliveryCourierId && assignable && !selected) setSelected(courier);
  }, [courier]);

  const pickupOrDeliveryTimeText = pickupTime
    ? translate(translations.PICKUP_ESTIMATIONS(Math.round(parseFloat(pickupTime))))
    : translate(translations.DELIVERY_ESTIMATIONS(Math.round(parseFloat(deliveryTime))));

  const deliveryFeesText = `• ${translate(translations.DELIVERY_COST)} ${translate(currency)} ${deliveryFees?.toFixed(
    currency.decimals,
  )} `;

  const mapCourierErrorCode = () => {
    switch (errorCode) {
      case COURIER_ERROR_CODE.VRD01:
        return (
          <Typography variant="body12" color={colors.gray[600]} cursor={assignable && 'pointer'}>
            {translate(translations.PAYMENT_TYPE_NOT_SUPPORTED)}
          </Typography>
        );
      default:
        return (
          <Typography variant="body12" color={colors.gray[600]} cursor={assignable && 'pointer'}>
            {translate(translations.NO_COURIER_ESTIMATION)}
          </Typography>
        );
    }
  };

  const getCourierError = () => {
    if (assignable) {
      if (success) {
        return (
          <Typography variant="body12" color={colors.gray[600]} cursor={assignable && 'pointer'}>
            {pickupOrDeliveryTimeText} {deliveryFeesText}
          </Typography>
        );
      }
      return (
        <Typography variant="body12" color={colors.gray[600]} cursor={assignable && 'pointer'}>
          {translate(translations.NO_COURIER_ESTIMATION)}
        </Typography>
      );
    }
    return mapCourierErrorCode();
  };

  return (
    <div
      className={cx(
        'w-100 flex border items-center justify-between mb-2 rounded-lg py-3 px-4 cursor-pointer',
        isCourierSelected && 'border-blue-500 bg-blue-100',
        !assignable ? 'bg-gray-100 cursor-default' : 'cursor-pointer',
      )}
      onClick={() => assignable && setSelected(courier)}
      aria-hidden="true"
    >
      <div className="w-full relative flex justify-between items-center">
        <div className="flex gap-3 items-center">
          <div className="w-6 h-6 flex">
            {!logoUrl ? <ShimmerCircle /> : <img className="rounded-full shadow-md" src={logoUrl} alt="courier logo" />}
          </div>
          <div>
            <Typography variant="heading14" mb={4} cursor={assignable && 'pointer'}>
              {isArabic ? courierNameAr : courierNameEn}
            </Typography>
            {isCouriersEstimationsLoading ? <Spinner /> : getCourierError()}
          </div>
        </div>
        {inNetwork && (
          <div className="mx-2 px-2 py-1flex items-center">
            <div className="live-stream-icon" />
          </div>
        )}
      </div>
    </div>
  );
};
StoreCourier.propTypes = {
  order: PropTypes.shape({
    number: PropTypes.string,
    id: PropTypes.number,
    status: PropTypes.string,
    deliveryCourier: {
      driverAssigned: PropTypes.bool,
      driverName: PropTypes.string,
      driverPhoneNumber: PropTypes.number,
      referenceId: PropTypes.string,
      externalOrderIdentifierLink: PropTypes.string,
      externalOrderIdentifierType: PropTypes.string,
      trackingLink: PropTypes.string,
      hasDriverInfo: PropTypes.bool,
      courierDetails: PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.string,
        displayNameAr: PropTypes.string,
        displayNameEn: PropTypes.string,
      }),
    },
  }),
  courier: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    displayNameEn: PropTypes.string,
    displayNameAr: PropTypes.string,
    supportCancellation: PropTypes.bool,
    courierId: PropTypes.string,
    courierDetails: PropTypes.shape({
      displayNameAr: PropTypes.string,
      displayNameEn: PropTypes.string,
    }),
    courierEstimations: PropTypes.shape({
      courierId: PropTypes.number,
      deliveryFees: PropTypes.number,
      deliveryTime: PropTypes.number,
      pickupTime: PropTypes.number,
      success: PropTypes.bool,
      supported: PropTypes.bool,
    }),
  }),
  deliveryCourierId: PropTypes.number,
};

export default StoreCourier;
