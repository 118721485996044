import React, { useContext } from 'react';
import cx from 'classnames';
import { Typography } from '@zydalabs/zac-react';
import PropTypes from 'prop-types';
import 'style.css';

import { Text } from 'components/service';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import * as translations from 'constants/translations';
import { DELIVERY_BUSY_MODE } from 'components/common/branch/Busy/utils';
import { ORDER_STATUS } from 'constants/order';
import { ManualOrderMark, getDeliveryTypeText } from '../utils/orderSectionUtils';
import DeliveryCircularProgress from './DeliveryCircularProgress';
import { DELIVERY_ENUMS } from '../../constants';
import { PAYMENT_METHODS_ENUMS } from '../../../Frame/utils';
import OrderDate from './OrderDate';
import StatusOrder from './StatusOrder';
import PaymentStatusOrder from './PaymentStatusOrder';

const OrderItemMobileNew = ({
  order,
  paidThrough,
  deliveryType,
  branchName,
  branchData,
  OrderActionButton,
  total,
  createdAt,
  number,
  isManualOrder,
  customerName,
  isSelected,
  isOrderUpdating,
  isFaded,
  paymentStatus,
  onClick,
  deliveryZone,
  status,
  areaNameEn,
  areaNameAr,
}) => {
  const { lang, direction, translate } = useContext(localeContext);
  const { selectedStore } = useContext(userContext);
  const { currency } = selectedStore;
  const areaName = lang === 'en' ? areaNameEn : areaNameAr || areaNameEn;

  return (
    <div
      role="presentation"
      onClick={onClick}
      className={cx(
        'flex flex-col bg-gray-100 py-3 cursor-pointer',
        isSelected && 'bg-primary-base/10',
        (isFaded || isOrderUpdating) && 'opacity-25',
      )}
    >
      <div className="flex">
        <div
          role="presentation"
          className={cx(
            'flex flex-col md:flex-wrap items-center',
            lang === 'ar' ? 'md:flex-row-reverse' : 'md:flex-row',
          )}
        >
          <div
            className={cx(
              'px-4 w-full flex-1 flex',
              lang === 'ar' && 'flex-row-reverse',
              'flex-wrap items-center select-none',
            )}
          >
            <div className="flex w-full">
              <OrderDate timeZone={selectedStore.timeZone} date={createdAt} />
            </div>
            <div className={cx('w-full flex items-center', lang === 'ar' ? 'flex-row-reverse' : 'flex-row ')}>
              <div className={cx('flex items-center', lang === 'ar' && 'flex-row-reverse')}>
                <span className="text-gray-700 text-xs">{getDeliveryTypeText(deliveryType)}</span>
                <div className="px-1">-</div>
                <span className={cx('text-xs', lang === 'ar' ? 'text-left' : 'text-right')}>
                  {deliveryType === DELIVERY_BUSY_MODE.PICKUP && (branchData ? translate(branchData) : branchName)}
                  {// checking if store use new delivery zone system (useDeliveryzonesMs)
                  // NOTE later we will migrate all stores to new system
                  deliveryType === DELIVERY_ENUMS.DELIVERY_SMALL &&
                    `${deliveryZone?.zoneName ? deliveryZone.zoneName : areaName}`}
                </span>
              </div>
            </div>
            <div
              className={cx(
                'w-full flex items-center justify-between',
                lang === 'ar' ? 'flex-row-reverse' : 'flex-row ',
              )}
            >
              <div className={cx('w-full my-2', lang === 'ar' ? 'text-right' : 'text-left')}>
                <div className="w-full flex items-center">
                  <span className={cx(lang === 'ar' ? 'ml-1' : 'mr-1', 'block text-gray-700 text-xs')}>
                    #<span className="select-all cursor-text">{number}</span>
                  </span>
                  <div className={cx('flex items-center md:w-1/5', lang === 'ar' ? ' flex-row-reverse' : 'flex-row')}>
                    {status !== ORDER_STATUS.PENDING ? (
                      <StatusOrder status={status} />
                    ) : (
                      <PaymentStatusOrder paymentStatus={paymentStatus} />
                    )}
                  </div>
                </div>
                <div className="text-truncation">
                  <Typography variant="heading16" cursor="pointer">
                    {customerName}
                  </Typography>
                </div>
              </div>
            </div>
            <div
              className={cx(
                'w-full flex items-center justify-between',
                lang === 'ar' ? 'flex-row-reverse' : 'flex-row ',
              )}
            >
              <div className={cx(lang === 'ar' ? 'text-right' : 'text-left')}>
                <div className={cx('flex items-center justify-start', lang === 'ar' ? 'flex-row-reverse' : 'flex-row')}>
                  {isManualOrder && <ManualOrderMark lang={lang} />}
                  <div className={cx(lang === 'ar' ? 'text-right' : 'text-left', 'text-xs text-gray-700')}>
                    <span style={{ direction }} className="block">
                      <Text value={currency} className="inline mr-1" />
                      {total.toFixed(currency.decimals)}
                    </span>
                  </div>
                  <div
                    className={cx(
                      'flex items-center',
                      lang === 'ar' ? 'text-right mr-1' : 'text-left ml-1',
                      'text-xs text-gray-700',
                    )}
                  >
                    {paidThrough === PAYMENT_METHODS_ENUMS.CASH && (
                      <div
                        className={cx(
                          lang === 'ar' ? 'mr-1' : 'ml-1',
                          'text-xs text-red-700 px-1 bg-[#fdf0ef] border border-red-700',
                        )}
                      >
                        <Text value={translations.UNPAID_ORDER} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={cx('flex justify-end px-1', lang === 'ar' ? ' flex-row-reverse' : 'flex-row')}>
          <DeliveryCircularProgress order={order} isSmall />
        </div>
      </div>
      <div className="px-2 mt-4">{OrderActionButton}</div>
    </div>
  );
};

OrderItemMobileNew.propTypes = {
  paidThrough: PropTypes.string,
  isOrderUpdating: PropTypes.bool,
  isSelected: PropTypes.bool,
  customerName: PropTypes.string,
  areaNameEn: PropTypes.string,
  areaNameAr: PropTypes.string,
  deliveryType: PropTypes.string,
  isManualOrder: PropTypes.bool,
  branchName: PropTypes.string,
  branchData: PropTypes.shape({
    id: PropTypes.string,
    lat: PropTypes.number,
    lng: PropTypes.number,
    titleAr: PropTypes.string,
    titleEn: PropTypes.string,
  }),
  total: PropTypes.number,
  isFaded: PropTypes.bool,
  onClick: PropTypes.func,
  deliveryZone: PropTypes.shape({
    zoneName: PropTypes.string,
  }),
};

export default OrderItemMobileNew;
