import React, { useContext } from 'react';
import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Typography, useTheme } from '@zydalabs/zac-react';
import 'style.css';

import { Text } from 'components/service';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import { ClockIcon } from '@heroicons/react/solid';
import { PaidMethodsIcon } from 'utils/imagesResolver';
import CheckBox from 'components/kit/CheckBox';
import PaidThrough from '../PaidThrough';
import DeliveryCircularProgress from './DeliveryCircularProgress';
import { getOrderIcon, getDeliveryTypeText, BulkChangeTip, ManualOrderMark } from '../utils/orderSectionUtils';

const OrderItemDesktopNew = ({
  updateOrderSelectedState = () => {
    // Do Nothing
  },
  isOrderUpdating,
  bulkChangeStatus,
  isFaded,
  onClick,
  textSize,
  showOrderingUser,
  order,
  currentTabStatus,
  OrderActionButton,
}) => {
  const { lang, direction, translate } = useContext(localeContext);
  const { selectedStore, settings } = useContext(userContext);
  const { colors } = useTheme();
  const { currency, timeZone } = selectedStore;
  const {
    deliveryStatus,
    id,
    isSelected,
    areaNameEn,
    areaNameAr,
    paidThrough,
    number,
    deliveryType,
    branchName,
    branchData,
    total,
    gift,
    customerName,
    isManualOrder,
    submittedAt,
    createdAt,
  } = order || {};
  const areaName = lang === 'en' ? areaNameEn : areaNameAr || areaNameEn;
  const isVerdEnabled = settings?.enableVerd;
  const branchTitle = branchData ? translate(branchData) : branchName;
  const date = submittedAt || createdAt;

  const timeOfCreationText = `${moment
    .tz(date, timeZone)
    .locale(lang === 'ar' ? 'ar' : 'en-gb')
    .format('h:mm A, DD MMM')}`;

  const orderNumberAndUserName = (
    <div className={cx('px-2', lang === 'ar' ? 'text-right' : 'text-left')}>
      {showOrderingUser ? (
        <>
          <Typography variant="element14" color={colors.positive.secondary} cursor="pointer">
            {timeOfCreationText}
          </Typography>
          <div className="text-truncation">
            <Typography variant="heading16" cursor="pointer">
              {customerName}
            </Typography>
          </div>
        </>
      ) : (
        <>
          <span className="block text-gray-700 text-xs">Order No.</span>
          <span className="block text-sm">
            #<span className="select-all cursor-text">{number}</span>
          </span>
        </>
      )}
    </div>
  );

  const deliveryOrPickupText = (
    <>
      <span
        className={cx(
          lang === 'ar' ? 'ml-2' : 'mr-2',
          'rounded-full bg-gray-200 w-8 h-8 px-2 flex items-center justify-center',
        )}
      >
        <span className="h-5">{getOrderIcon(deliveryType, gift, deliveryStatus, currentTabStatus)}</span>
      </span>
      <div className={cx('px-2', lang === 'ar' ? 'text-right' : 'text-left')}>
        <Typography variant="element14" color={colors.positive.secondary} cursor="pointer">
          {getDeliveryTypeText(deliveryType)}
        </Typography>
        <div className="line-clamp-2">
          <Typography variant="heading16" cursor="pointer">
            {deliveryType === 'delivery' ? `${areaName}` : branchTitle}
          </Typography>
        </div>
      </div>
    </>
  );

  const paymentText = (
    <div className="flex items-center">
      <div className="flex items-center justify-center rounded-full bg-gray-200 w-8 h-8 flex-shrink-0">
        <PaidMethodsIcon paidThrough={paidThrough} />
      </div>
      <div className={cx('px-2', lang === 'ar' ? 'text-right' : 'text-left', textSize !== 'sm' && 'pl-2')}>
        <Typography variant="element14" color={colors.positive.secondary} cursor="pointer">
          <PaidThrough paidThrough={paidThrough} />
        </Typography>
        <div>
          <Typography variant="heading16" cursor="pointer">
            {total.toFixed(currency.decimals)}
            <Text value={currency} className="inline" />
          </Typography>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {isOrderUpdating && <BulkChangeTip status={bulkChangeStatus} />}
      <div
        role="presentation"
        data-tooltip-id={isOrderUpdating && 'bulkChangeTip'}
        className={cx(
          'flex md:flex-wrap bg-gray-100 relative p-5 items-center cursor-pointer',
          isSelected && 'bg-primary-base/10',
          (isFaded || isOrderUpdating) && 'opacity-25',
        )}
        onClick={onClick}
        data-testid="order-number-select"
        style={{ direction }}
      >
        <div className="flex-initial border-b w-34 md:border-b-0 border-gray-200 flex flex-row justify-center items-center">
          {!isOrderUpdating && isVerdEnabled && (
            <CheckBox value={isSelected} lang={lang} onChange={value => updateOrderSelectedState(id, value)} />
          )}
          {isOrderUpdating && (
            <ClockIcon className={cx('w-5 h-5 text-zyda-yellow-500', lang === 'ar' ? 'ml-3' : 'mr-3')} />
          )}
        </div>
        <div className={cx('w-full flex-1 flex items-center select-none gap-2', lang === 'ar' ? 'ml-4' : 'mr-4')}>
          <div className="p-2 order-4 md:order-none md:p-0 flex items-center w-1/3 justify-start">
            {orderNumberAndUserName}
          </div>
          <div className="flex items-center justify-start w-1/3">{deliveryOrPickupText}</div>
          <div className="flex items-center w-1/3">{paymentText}</div>
          <div className="w-1/6 p-0 flex items-center justify-start">
            <DeliveryCircularProgress order={order} />
          </div>
        </div>
        <div className={cx('flex items-center w-5/12 justify-center', lang === 'ar' ? 'ml-5' : 'mr-5')}>
          {OrderActionButton}
        </div>
        <div className={cx('absolute', lang === 'ar' ? 'left-4' : 'right-4')}>
          {isManualOrder && <ManualOrderMark />}
        </div>
      </div>
    </>
  );
};

OrderItemDesktopNew.propTypes = {
  updateOrderSelectedState: PropTypes.func,
  isOrderUpdating: PropTypes.bool,
  bulkChangeStatus: PropTypes.string,
  userData: PropTypes.shape({
    name: PropTypes.string,
    address: PropTypes.shape({
      area: PropTypes.shape({ cityTitleEn: PropTypes.string, cityTitleAr: PropTypes.string }),
    }),
  }),
  branchData: PropTypes.shape({
    id: PropTypes.string,
    lat: PropTypes.number,
    lng: PropTypes.number,
    titleAr: PropTypes.string,
    titleEn: PropTypes.string,
  }),
  isFaded: PropTypes.bool,
  onClick: PropTypes.func,
  textSize: PropTypes.string,
  showOrderingUser: PropTypes.bool,
  deliveryZone: PropTypes.shape({
    zoneName: PropTypes.string,
  }),
};

export default OrderItemDesktopNew;
