import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import cx from 'classnames';
import { ListBulletIcon } from '@zydalabs/zac-icons-react';
import { Accordion, Divider, Typography, useTheme } from '@zydalabs/zac-react';
import PropTypes from 'prop-types';
import 'style.css';

import { useMobile, useSelectedStore } from 'hooks';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import { ORDER_STATUS } from 'constants/order';
import * as translations from 'constants/translations';
import { Text } from 'components/service';
import RefundOptionsModal from 'components/common/orders/RefundOptions/RefundOptionsModal';
import { Spinner, Modal, Button } from 'components/kit';
import { THIRD_PARTY_TYPE_ENUM } from 'utils/enums';
import { ReactComponent as ShoppingBag } from 'assets/shopping-bag.svg';
import * as schemas from '../../schemas';
import { isCourierAvailable } from '../helpers';
import OrderDetailsRefundInfo from './OrderDetailsRefundInfo';
import OrderDetailsItems from './OrderDetailsItems';
import OrderDetailsStatusHistory from './OrderDetailsStatusHistory';
import OrderDetailsFees from './OrderDetailsFees';
import OrderDetailsPrint from './OrderDetailsPrint';
import OnFleetDeliveryActionButtons from './verdSection/OnFleetDeliveryActionButtons';
import OrderDetailsPaymentInfo from './OrderDetailsPaymentInfo';
import { CancelOrder } from './CancelOrderButton';
import { DELIVERY_ENUMS } from '../../constants';
import { REFUND_TYPE_ENUM } from './CancelOrderButton/CancelOrderBody/data';
import OrderDetailsPayments from './OrderDetailsPayments';
import DeliveryFees from './DeliveryFees';
import ContactInformation from './ContactInformationSection';

const OrderDetails = ({ id, ordersVariables, OrderActionButton, newOrders }) => {
  const storeId = useSelectedStore();
  const user = useContext(userContext);
  const { lang, translate, direction } = useContext(localeContext);
  const isMobile = useMobile();
  const { colors } = useTheme();
  const order = useQuery(schemas.ORDER, { variables: { storeId, orderId: id } });
  const {
    selectedStore: { restaurantCourierSetting },
    settings,
  } = user;
  const filteredRestaurantCourierSetting = restaurantCourierSetting.filter(
    courier => !courier.isInternalDelivery && courier.businessType === THIRD_PARTY_TYPE_ENUM.Delivery,
  );
  const isVerdEnabled = settings?.enableVerd;
  const {
    selectedStore,
    isPosCourier: isPosBusiness,
    isDeliveryCourier: isDeliveryBusiness,
    courier,
    legalData,
  } = useContext(userContext);

  const orderData = order?.data?.order;
  const {
    id: orderId,
    number,
    total,
    deliveryCourier,
    paidByWallet,
    paidByCreditCard,
    status: orderStatus,
    updatingStatus,
    deliveryCourierId,
    deliveryType,
    status,
    paidThrough,
    typeOfRefund,
    paymentStatus,
    orderItems,
  } = orderData || {};

  const isBulkChange = updatingStatus?.orderGettingUpdated;
  const isDeliveryOrder = deliveryType === DELIVERY_ENUMS.DELIVERY_SMALL;

  const { currency } = selectedStore;
  const orderCurrency = lang === 'en' ? currency.titleEn : currency.titleAr;

  const orderTotalWithCurrecy = `${total} ${orderCurrency}`;

  const hasCourier = isDeliveryBusiness && deliveryCourierId;

  const canBeSendToCourier =
    orderData &&
    isCourierAvailable({
      deliveryType,
      restaurantCourierSetting: courier,
      isDeliveryBusiness,
    });

  const shouldShowRefundOrderButton =
    (user.hasRole('owner') || user.hasRole('ops_manager')) &&
    status === ORDER_STATUS.CANCELED &&
    [ORDER_STATUS.PAID, ORDER_STATUS.REFUND_FAILED].includes(paymentStatus) &&
    ![
      REFUND_TYPE_ENUM.CREDIT_CARD,
      REFUND_TYPE_ENUM.ORDERFAST_WALLET,
      REFUND_TYPE_ENUM.WALLET_AND_CREDIT_CARD,
    ].includes(typeOfRefund) &&
    !['CARDONDELIVERY', 'Cash', 'CASH'].includes(paidThrough);

  return (
    <Modal>
      {({ open, close }) =>
        order.loading ? (
          <Spinner />
        ) : (
          <>
            <div
              className="flex flex-col items-start bg-gray-100 p-5"
              data-testid="order-item-body"
              style={{ direction }}
            >
              <div className="flex gap-2 w-full flex-wrap">
                <div className="w-full md:w-1/2 flex flex-col items-start gap-2">
                  <div className="bg-white w-full py-3 px-1" style={{ direction }}>
                    <Accordion
                      accordionItems={[
                        {
                          title: (
                            <div className="flex items-center gap-2">
                              <ShoppingBag fill={colors.positive.tertiary} />
                              <Typography variant="heading20" cursor="pointer">
                                {translate(translations.ITEMS, lang, orderItems?.length)}
                              </Typography>
                            </div>
                          ),
                          content: (
                            <>
                              <OrderDetailsItems order={orderData} currency={currency} />
                              <OrderDetailsFees order={orderData} currency={currency} deliveryType={deliveryType} />
                            </>
                          ),
                          isExpanded: true,
                        },
                      ]}
                      size="small"
                      topDivider={false}
                      bottomDivider={false}
                    />
                  </div>
                  <div className="w-full flex flex-col bg-white p-5">
                    <div className="w-full flex flex-col justify-between gap-2" style={{ direction }}>
                      <OrderDetailsPayments
                        lang={lang}
                        paidThrough={paidThrough}
                        paidByWallet={paidByWallet}
                        paidByCreditCard={paidByCreditCard}
                        currency={currency}
                      />
                      <div className="zac-divider">
                        <Divider />
                      </div>
                      <OrderDetailsPrint
                        order={orderData}
                        canBeSendToCourier={canBeSendToCourier}
                        selected={selectedStore}
                        legalData={legalData}
                        isDeliveryBusiness={isDeliveryBusiness}
                        deliveryCourier={deliveryCourier}
                        courierDetails={courier?.courierDetails}
                        orderStatus={orderStatus}
                      />
                    </div>
                    <DeliveryFees order={orderData} currency={currency} />
                  </div>

                  {[
                    REFUND_TYPE_ENUM.CREDIT_CARD,
                    REFUND_TYPE_ENUM.ORDERFAST_WALLET,
                    REFUND_TYPE_ENUM.WALLET_AND_CREDIT_CARD,
                  ].includes(typeOfRefund) && <OrderDetailsRefundInfo order={orderData} lang={lang} />}

                  <OrderDetailsPaymentInfo order={orderData} selected={selectedStore} />
                </div>
                <div className="flex flex-col md:w-1/2 flex-1 w-full gap-2">
                  <div className="bg-white p-5" style={{ direction }}>
                    <ContactInformation
                      order={orderData}
                      cancelOrder={
                        <CancelOrder
                          isPosBusiness={isPosBusiness}
                          order={orderData}
                          isBulkChange={isBulkChange}
                          open={open}
                          ordersVariables={ordersVariables}
                          close={close}
                          status={status}
                          hasCourier={hasCourier}
                          isMobile={isMobile}
                          lang={lang}
                        />
                      }
                    />
                  </div>
                  {isDeliveryOrder &&
                    !(
                      [ORDER_STATUS.CANCELED].includes(orderStatus) ||
                      (orderStatus === ORDER_STATUS.PENDING &&
                        [
                          ORDER_STATUS.PAYMENT_FAILED,
                          ORDER_STATUS.WAITING_FOR_PAYMENT,
                          ORDER_STATUS.PAYMENT_EXPIRED,
                        ].includes(paymentStatus))
                    ) &&
                    (isVerdEnabled || filteredRestaurantCourierSetting.length > 0) && (
                      <OnFleetDeliveryActionButtons
                        order={orderData}
                        ordersVariables={ordersVariables}
                        newOrders={newOrders}
                      />
                    )}

                  <div className="bg-white py-3 px-1" style={{ direction }}>
                    <Accordion
                      accordionItems={[
                        {
                          title: (
                            <div className="flex items-center gap-2">
                              <ListBulletIcon width="24px" color={colors.positive.tertiary} />
                              <Typography variant="heading20" cursor="pointer">
                                {translate(translations.ORDER_TIMELINE)}
                              </Typography>
                            </div>
                          ),
                          content: (
                            <OrderDetailsStatusHistory
                              order={orderData}
                              selected={selectedStore}
                              isDeliveryBusiness={isDeliveryBusiness}
                              hasCourier={hasCourier}
                            />
                          ),

                          isExpanded: true,
                        },
                      ]}
                      size="small"
                      topDivider={false}
                      bottomDivider={false}
                    />
                  </div>
                </div>
              </div>
              <div className={cx('w-full md:w-1/2 flex justify-between', isMobile ? 'flex-col mt-4' : 'mt-2')}>
                {shouldShowRefundOrderButton && (
                  <div
                    className={cx(
                      'flex flex-col mt-4',
                      isMobile && 'flex-wrap-reverse md:flex-nowrap',
                      'justify-between',
                    )}
                  >
                    <Button
                      onClick={() =>
                        open({
                          title: (
                            <Text
                              value={translations.REFUND_OPTIONS_MODAL_TITLE(number, orderTotalWithCurrecy)}
                              className="text-black font-medium"
                            />
                          ),
                          body: (
                            <RefundOptionsModal
                              open={open}
                              close={close}
                              lang={lang}
                              orderPaidThrough={paidThrough}
                              orderId={orderId}
                              orderNumber={number}
                              currency={orderCurrency}
                              orderTotal={total}
                            />
                          ),
                          size: 'max-w-sm',
                        })
                      }
                      isRounded
                      kind="secondaryError"
                      className="border-red-500 hover:border-red-700 hover:text-red-700"
                      size="base"
                    >
                      <Text value={translations.REFUND_ORDER} />
                    </Button>
                  </div>
                )}
                {OrderActionButton}
              </div>
            </div>
          </>
        )
      }
    </Modal>
  );
};

OrderDetails.propTypes = {
  currency: PropTypes.shape({
    decimals: PropTypes.number.isRequired,
    titleAr: PropTypes.string.isRequired,
    titleEn: PropTypes.string.isRequired,
  }),
  id: PropTypes.number.isRequired,
  ordersVariables: PropTypes.shape({
    areas: PropTypes.arrayOf(PropTypes.string),
    branchId: PropTypes.string,
    customerName: PropTypes.string,
    deliveryType: PropTypes.arrayOf(PropTypes.string),
    number: PropTypes.number,
    page: PropTypes.number,
    paymentMethod: PropTypes.arrayOf(PropTypes.string),
    paymentStatuses: PropTypes.string,
    phone: PropTypes.string,
    status: PropTypes.string,
    statuses: PropTypes.string,
    storeId: PropTypes.string,
    submittedAt: PropTypes.string,
    voucherCode: PropTypes.string,
  }),
  legalData: PropTypes.shape({
    companyNameEn: PropTypes.string,
    companyNameAr: PropTypes.string,
    vatIdNumber: PropTypes.string,
  }),
  newOrders: PropTypes.shape({
    areas: PropTypes.arrayOf(PropTypes.string),
    branchId: PropTypes.string,
    customerName: PropTypes.string,
    deliveryType: PropTypes.arrayOf(PropTypes.string),
    number: PropTypes.number,
    page: PropTypes.number,
    paymentMethod: PropTypes.arrayOf(PropTypes.string),
    paymentStatuses: PropTypes.string,
    phone: PropTypes.number,
    sort: PropTypes.objectOf({ order: PropTypes.string, field: PropTypes.string }),
    status: PropTypes.string,
    statuses: PropTypes.string,
    storeId: PropTypes.string,
    submittedAt: PropTypes.string,
    voucherCode: PropTypes.string,
  }),
};
export default OrderDetails;
