import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Spinner, Accordion, useTheme } from '@zydalabs/zac-react';
import { RepeatIcon } from '@zydalabs/zac-icons-react';

import { ORDER_STATUS } from 'constants/order';
import * as translations from 'constants/translations';
import { useMobile, useSelectedStore } from 'hooks';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import Slideover from 'components/common/dashboard/Layout/Slideover/Slideover';
import { ReactComponent as VerdIcon } from 'assets/verd-icon.svg';
import { Button, ShimmerCircle } from 'components/kit';
import { useFetchVerdDrivers } from 'service/hooks';
import StoreRiderInfo from './StoreRiderInfo';
import StoreCourierInfo from './StoreCourierInfo';
import useHandleAssignUnAssignDriverToOrder from '../../utils/handleAssignDrivertoOrder';
import useHandleAssignUnAssignCourierToOrder from '../../utils/handleAssignCourierToOrder';
import AssignDeliveryModal from './AssignDeliveryModal';
import useGetCourierInfoWithLogo from '../../utils/useGetCourierInfoWithLogo';
import { DELIVERY_ENUMS } from '../../../constants';

const OnFleetDeliveryActionButtons = ({ order }) => {
  const { translate, lang } = useContext(localeContext);
  const isMobile = useMobile();
  const selectedStoreId = useSelectedStore();
  const { settings } = useContext(userContext);
  const { colors } = useTheme();
  const [isDeliverySlideOverOpen, setIsDeliverySlideOverOpen] = useState(false);

  const isVerdEnabled = settings?.enableVerd;
  const isArabic = lang === 'ar';

  const {
    status: orderStatus,
    branchId,
    deliveryCourier,
    deliveryStatus: deliveryOrderStatus,
    deliveryType,
    paymentStatus,
    driverId,
  } = order || {};

  const { courierId: orderDeliveryCourierId, referenceId } = deliveryCourier || {};

  const { data: activeRiders, isLoading: isActiveRidersLoading, mutate: mutateFetchVerdDrivers } = useFetchVerdDrivers({
    storeId: selectedStoreId,
    branchId: parseInt(branchId),
  });

  const {
    restaurantCouriersWithLogos,
    isCouriersEstimationsLoading,
    courierEstimationsError,
  } = useGetCourierInfoWithLogo(order);

  const { handleAssignCourierToOrder, isCourierInfoLoading } = useHandleAssignUnAssignCourierToOrder({
    order,
    mutateFetchVerdDrivers,
  });

  const { handleAssignDriverToOrder, isRiderInfoLoading } = useHandleAssignUnAssignDriverToOrder({
    order,
    mutateFetchVerdDrivers,
  });

  const riders = activeRiders?.length ? activeRiders.filter(rider => rider.onShift) : [];

  const assignedDriver = activeRiders?.find(driver => parseInt(driver.id) === driverId);
  const assignedCourier = restaurantCouriersWithLogos?.find(
    courier => Number(courier.courierId) === orderDeliveryCourierId,
  );
  const isOrderAssignedToCourierOrRider = !!assignedDriver?.id || !!assignedCourier?.courierId;

  const isDeliveryOrder = deliveryType === DELIVERY_ENUMS.DELIVERY_SMALL;
  const isOrderCancelled = [ORDER_STATUS.CANCELED].includes(orderStatus);
  const isOrderWithPaymentIssues =
    orderStatus === ORDER_STATUS.PENDING &&
    [ORDER_STATUS.PAYMENT_FAILED, ORDER_STATUS.WAITING_FOR_PAYMENT, ORDER_STATUS.PAYMENT_EXPIRED].includes(
      paymentStatus,
    );
  const shouldShowVerdSection =
    isDeliveryOrder &&
    !(isOrderCancelled || isOrderWithPaymentIssues) &&
    (isVerdEnabled || restaurantCouriersWithLogos.length > 0) &&
    isOrderAssignedToCourierOrRider;

  const getAccordionTitle = () =>
    !isRiderInfoLoading && !!assignedDriver ? (
      <div className="flex items-center gap-2">
        <VerdIcon fill={colors.green[500]} />
        <Typography variant="heading20" cursor="pointer">
          {translate(translations.DELIVERY_BY_VERD)}
        </Typography>
      </div>
    ) : (
      <div className="flex items-center gap-2">
        <div className="w-6 h-6">
          {!assignedCourier?.logoUrl ? (
            <ShimmerCircle />
          ) : (
            <img className="rounded-full shadow-md" src={assignedCourier?.logoUrl} alt="courier logo" />
          )}
        </div>
        <div className="flex items-center gap-1">
          <Typography variant="heading20" cursor="pointer">
            {isArabic
              ? translate(translations.DELIVERY_BY_COURIER, lang, assignedCourier?.courierDetails?.displayNameAr)
              : translate(translations.DELIVERY_BY_COURIER, lang, assignedCourier?.courierDetails?.displayNameEn)}
          </Typography>
          {referenceId && (
            <Typography variant="body14" color={colors.positive.secondary} cursor="pointer">{`(${translate(
              translations.REQUEST_ID,
            )}: ${referenceId})`}</Typography>
          )}
        </div>
      </div>
    );

  const reassignButton = ![ORDER_STATUS.DELIVERED, ORDER_STATUS.CANCELED].includes(orderStatus) && (
    <Button
      size="md"
      isRounded
      kind="primary"
      icon={<RepeatIcon width="20px" color={colors.white} />}
      style={{
        backgroundColor: colors.green.primary,
        color: colors.white,
        border: 'none',
      }}
      isSpinning={isRiderInfoLoading || isCourierInfoLoading}
      onClick={() => setIsDeliverySlideOverOpen(true)}
      data-testid="assign-delivery-button"
    >
      {translate(translations.REASSIGN)}
    </Button>
  );

  if (isActiveRidersLoading || isCourierInfoLoading || isRiderInfoLoading) {
    return (
      <div className="w-full bg-white py-3 px-1 flex items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      {shouldShowVerdSection && (
        <div className="w-full bg-white py-3 px-1">
          <Accordion
            accordionItems={[
              {
                title: getAccordionTitle(),
                content: (
                  <>
                    {!isRiderInfoLoading && !!assignedDriver && (
                      <StoreRiderInfo
                        deliveryOrderStatus={deliveryOrderStatus}
                        assignedDriver={assignedDriver}
                        order={order}
                        reassignButton={reassignButton}
                      />
                    )}
                    {!isCourierInfoLoading && !!assignedCourier && (
                      <StoreCourierInfo
                        order={order}
                        assignedCourier={assignedCourier}
                        reassignButton={reassignButton}
                        isOrderDeliveryCourierNotVerd={!assignedDriver}
                        isOrderAssignedToCourierOrRider={isOrderAssignedToCourierOrRider}
                      />
                    )}
                  </>
                ),

                isExpanded: true,
              },
            ]}
            size="small"
            topDivider={false}
            bottomDivider={false}
          />
          <Slideover
            isOpen={isDeliverySlideOverOpen}
            fullWidth={isMobile}
            body={
              <AssignDeliveryModal
                order={order}
                mutateFetchVerdDrivers={mutateFetchVerdDrivers}
                close={() => setIsDeliverySlideOverOpen(false)}
                handleAssignDriverToOrder={handleAssignDriverToOrder}
                handleAssignCourierToOrder={handleAssignCourierToOrder}
                assigned={assignedDriver || assignedCourier}
                restaurantCouriersWithLogos={restaurantCouriersWithLogos}
                riders={riders}
                isCouriersEstimationsLoading={isCouriersEstimationsLoading && !courierEstimationsError}
              />
            }
          />
        </div>
      )}
    </>
  );
};

OnFleetDeliveryActionButtons.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.number,
    number: PropTypes.string,
    status: PropTypes.string.isRequired,
    deliveryTime: PropTypes.number,
    branchData: PropTypes.shape({ id: PropTypes.string }),
    restaurantRiderBranchOrderData: PropTypes.shape({ restaurantRider: PropTypes.string, status: PropTypes.string }),
    deliveryCourierId: PropTypes.number,
    deliveryStatus: PropTypes.string,
    isScheduled: PropTypes.bool,
    firingTime: PropTypes.number,
    expectedAt: PropTypes.string,
    deliveryCourier: PropTypes.shape({
      courierId: PropTypes.number,
      driverPhoneNumber: PropTypes.string,
      deliveryCourierId: PropTypes.number,
      trackingLink: PropTypes.string,
      referenceId: PropTypes.string,
      driverName: PropTypes.string,
      hasDriverInfo: PropTypes.bool,
      driverAssigned: PropTypes.string,
    }),
  }),
};
export default OnFleetDeliveryActionButtons;
