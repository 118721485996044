import React from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import 'style.css';

import { useMobile } from 'hooks';
import { ORDER_STATUS } from 'constants';
import OrderItemDesktop from './OrderItemDesktop';
import OrderItemDesktopNew from './OrderItemDesktopNew';

import OrderDetails from '../OrderDetails/OrderDetails';
import OrderItemMobile from './OrderItemMobile';
import { OrderDetailsActionButtons } from '../OrderDetails';
import OrderItemMobileNew from './OrderItemMobileNew';

const OrderItem = ({
  isOpened,
  isFaded,
  onClick,
  ordersVariables,
  newOrders,
  showOrderStatus,
  orderItemWidth,
  updateOrderSelectedState,
  isOrderUpdating,
  bulkChangeStatus,
  isBulkChange = false,
  item,
  showScheduled = true,
  showOrderingUser = true,
  textSize = 'sm',
}) => {
  const isMobile = useMobile();
  const { isManualOrder, customerName } = item;
  const currentTabStatus = ordersVariables?.status;

  return (
    <Formik
      onSubmit={(values, actions) => {
        setTimeout(() => {
          alert(JSON.stringify(values, null, 2));
          actions.setSubmitting(false);
        }, 1000);
      }}
    >
      <div className="mb-4 rounded-md flex flex-col gap-3" data-testid="order-item-block">
        {isMobile ? (
          currentTabStatus === ORDER_STATUS.CURRENT ? (
            <OrderItemMobileNew
              isFaded={isFaded}
              onClick={onClick}
              key={item.id}
              order={item}
              isManualOrder={isManualOrder}
              customerName={customerName}
              isSelected={item.isSelected}
              isOrderUpdating={isOrderUpdating}
              OrderActionButton={
                <OrderDetailsActionButtons order={item} ordersVariables={ordersVariables} newOrders={newOrders} />
              }
              {...item}
            />
          ) : (
            <OrderItemMobile
              isOpened={isOpened}
              isFaded={isFaded}
              onClick={onClick}
              key={item.id}
              id={item.id}
              isManualOrder={isManualOrder}
              customerName={customerName}
              isSelected={item.isSelected}
              updateOrderSelectedState={updateOrderSelectedState}
              isOrderUpdating={isOrderUpdating}
              bulkChangeStatus={bulkChangeStatus}
              isBulkChange={isBulkChange}
              ordersVariables={ordersVariables}
              newOrders={newOrders}
              showOrderStatus={showOrderStatus}
              orderItemWidth={orderItemWidth}
              showScheduled={showScheduled}
              {...item}
            />
          )
        ) : currentTabStatus === ORDER_STATUS.CURRENT ? (
          <OrderItemDesktopNew
            isFaded={isFaded}
            onClick={onClick}
            key={item.id}
            updateOrderSelectedState={updateOrderSelectedState}
            bulkChangeStatus={bulkChangeStatus}
            currentTabStatus={currentTabStatus}
            textSize={textSize}
            showOrderingUser={showOrderingUser}
            order={item}
            OrderActionButton={
              <OrderDetailsActionButtons order={item} ordersVariables={ordersVariables} newOrders={newOrders} />
            }
          />
        ) : (
          <OrderItemDesktop
            isOpened={isOpened}
            isFaded={isFaded}
            onClick={onClick}
            key={item.id}
            id={item.id}
            deliveryStatus={item.deliveryStatus}
            isManualOrder={isManualOrder}
            customerName={customerName}
            isSelected={item.isSelected}
            updateOrderSelectedState={updateOrderSelectedState}
            isOrderUpdating={isOrderUpdating}
            bulkChangeStatus={bulkChangeStatus}
            currentTabStatus={currentTabStatus}
            newOrders={newOrders}
            showOrderStatus={showOrderStatus}
            orderItemWidth={orderItemWidth}
            showScheduled={showScheduled}
            textSize={textSize}
            showOrderingUser={showOrderingUser}
            {...item}
          />
        )}
        {isOpened && (
          <OrderDetails
            ordersVariables={ordersVariables}
            id={item.id}
            OrderActionButton={
              <OrderDetailsActionButtons
                order={item}
                ordersVariables={ordersVariables}
                newOrders={newOrders}
                fromOrderDetails
              />
            }
          />
        )}
      </div>
    </Formik>
  );
};

OrderItem.propTypes = {
  isOpened: PropTypes.bool,
  isFaded: PropTypes.bool,
  onClick: PropTypes.func,
  isBulkChange: PropTypes.bool,
  isOrderUpdating: PropTypes.bool,
  bulkChangeStatus: PropTypes.string,
  updateOrderSelectedState: PropTypes.func,
  ordersVariables: PropTypes.shape({
    areas: PropTypes.arrayOf(PropTypes.string),
    branchId: PropTypes.string,
    customerName: PropTypes.string,
    deliveryType: PropTypes.arrayOf(PropTypes.string),
    number: PropTypes.number,
    page: PropTypes.number,
    paymentMethod: PropTypes.arrayOf(PropTypes.string),
    paymentStatuses: PropTypes.string,
    phone: PropTypes.string,
    status: PropTypes.string,
    statuses: PropTypes.string,
    storeId: PropTypes.string,
    submittedAt: PropTypes.string,
    voucherCode: PropTypes.string,
  }),
  showOrderStatus: PropTypes.bool,
  orderItemWidth: PropTypes.string,
  item: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.string,
    deliveryType: PropTypes.string,
    branchData: PropTypes.string,
    isSelected: PropTypes.bool,
    customerName: PropTypes.string,
    isManualOrder: PropTypes.bool,
    stateHistories: PropTypes.shape({
      actionBy: PropTypes.string,
      createdAt: PropTypes.string,
      state: PropTypes.string,
    }),
  }),
  showScheduled: PropTypes.bool,
  showOrderingUser: PropTypes.bool,
  textSize: PropTypes.string,
};

export default OrderItem;
