import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { REFUND_STATUS_ENUM } from '../CancelOrderButton/CancelOrderBody/data';
import { getRefundStatusColor, getRefundStatusText } from '../../helpers';

const RefundTapDetails = ({ total, refundId, refundDate, currency, lang, status }) => (
  <div className="flex flex-col bg-gray-100 rounded p-2">
    <div className="flex py-3 flex-row justify-between border-b-2 border-gray-300">
      <span className="text-xs font-semibold flex items-start">
        <Text value={translations.CREDIT_CARD} />
      </span>
      <span className="text-xs font-semibold flex items-start">
        <Text value={currency} className={cx('inline', lang === 'ar' ? 'ml-1' : 'mr-1')} />
        {total.toFixed(currency.decimals)}
      </span>
    </div>
    <div className="flex-1 flex flex-row py-3">
      <div className="flex-auto flex flex-col">
        <a href="https://businesses.tap.company/" target="_blank" className="text-xs text-gray-500" rel="noreferrer">
          <Text value={translations.PAYMENT_REFERENCE} />
        </a>
        <span className="text-xs">{refundId}</span>
      </div>
      <div className="flex-auto flex flex-col">
        <span className="text-xs text-gray-500">
          <Text value={translations.INITIATED_AT} />
        </span>
        <span className="text-xs">{refundDate}</span>
      </div>
      <div className="flex-auto flex flex-col">
        <span className="text-xs text-gray-500">
          <Text value={translations.STATUS_ORDER} />
        </span>
        <span className="flex flex-row justify-start items-center">
          <span className={cx('w-2 h-2 rounded-full mr-1', getRefundStatusColor(status))} />
          <span className="text-xs">
            <Text value={getRefundStatusText(status)} />
          </span>
        </span>
      </div>
    </div>
  </div>
);

RefundTapDetails.propTypes = {
  total: PropTypes.number.isRequired,
  refundId: PropTypes.string.isRequired,
  refundDate: PropTypes.string.isRequired,
  currency: PropTypes.shape({
    decimals: PropTypes.number.isRequired,
    titleAr: PropTypes.string.isRequired,
    titleEn: PropTypes.string.isRequired,
  }).isRequired,
  lang: PropTypes.oneOf(['en', 'ar']).isRequired,
  status: PropTypes.oneOf(REFUND_STATUS_ENUM).isRequired,
};

export default RefundTapDetails;
