import React, { useContext } from 'react';
import moment from 'moment';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { InfoIcon } from '@zydalabs/zac-icons-react';

import { Text } from 'components/service';
import { context as userContext } from 'context/user';
import * as translations from 'constants/translations';
import RefundTapDetails from './RefundTapDetails';
import RefundWalletDetails from './RefundWalletDetails';
import { RefundDisclamerText } from '../../helpers';
import { REFUND_TYPE_ENUM } from '../CancelOrderButton/CancelOrderBody/data';

const OrderDetailsRefundInfo = ({ lang, order }) => {
  const {
    refundTransactionsHistory,
    totalRefund,
    total,
    refundedAmount,
    compensation = {
      percentage: 0,
      amount: 0,
    },
    paidByCreditCard = 0,
    typeOfRefund,
  } = order || {};
  const refundTransactionsHistoryArrayLength = refundTransactionsHistory.length;
  const { refundId, updatedAt, status } = refundTransactionsHistory[refundTransactionsHistoryArrayLength - 1] || {};
  const refundDate = moment(updatedAt)
    .locale(lang === 'ar' ? 'ar' : 'en-gb')
    .format('D/MM/YYYY - hh:mm A');

  const {
    selectedStore: { currency },
  } = useContext(userContext);

  return (
    <div className={cx('flex flex-col w-full', lang === 'ar' && 'flex-row-reverse', 'my-4')}>
      <div className="flex flex-row mb-4">
        <span className="text-sm font-semibold">
          <Text value={translations.REFUND_INFO} />
        </span>
      </div>
      <div className="w-full p-4 bg-white rounded shadow">
        {typeOfRefund === REFUND_TYPE_ENUM.CREDIT_CARD && (
          <RefundTapDetails
            refundId={refundId}
            refundDate={refundDate}
            total={typeOfRefund === REFUND_TYPE_ENUM.CREDIT_CARD ? total : totalRefund}
            currency={currency}
            lang={lang}
            status={status}
          />
        )}
        {typeOfRefund === REFUND_TYPE_ENUM.ORDERFAST_WALLET && (
          <RefundWalletDetails
            refundedAmount={refundedAmount}
            compensation={compensation}
            currency={currency}
            lang={lang}
          />
        )}
        {typeOfRefund === REFUND_TYPE_ENUM.WALLET_AND_CREDIT_CARD && (
          <>
            <div className="py-3">
              <RefundWalletDetails
                refundedAmount={refundedAmount}
                compensation={compensation}
                currency={currency}
                lang={lang}
              />
            </div>
            <div className="py-3">
              <RefundTapDetails
                refundId={refundId}
                refundDate={refundDate}
                total={paidByCreditCard}
                currency={currency}
                lang={lang}
                status={status}
              />
            </div>
          </>
        )}

        <div className="py-3 pb-8 border-b-2">
          <div className="flex flex-row bg-yellow-100 border-yellow-200 text-black rounded border p-3">
            <InfoIcon width="20" height="20" color="#B48A00" />
            <RefundDisclamerText typeOfRefund={typeOfRefund} />
          </div>
        </div>
        <div className="py-4">
          <div className="flex flex-row justify-between border-gray-100">
            <span className="text-xs font-semibold flex items-start">
              <Text value={translations.TOTAL_REFUND} />
            </span>
            <span className="text-xs font-semibold flex items-start">
              <Text value={currency} className={cx('inline', lang === 'ar' ? 'ml-1' : 'mr-1')} />
              {typeOfRefund === REFUND_TYPE_ENUM.CREDIT_CARD
                ? total.toFixed(currency.decimals)
                : totalRefund.toFixed(currency.decimals)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

OrderDetailsRefundInfo.propTypes = {
  lang: PropTypes.oneOf(['en', 'ar']).isRequired,
};
export default OrderDetailsRefundInfo;
