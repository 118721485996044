import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { CheckCircleIcon } from '@zydalabs/zac-icons-react';
import { Typography, useTheme } from '@zydalabs/zac-react';
import { ORDER_STATUS } from 'constants/order';
import { ACTION_BY } from 'constants/translations';
import { context as localeContext } from 'context/locale';
import * as translations from 'constants/translations';
import { ReactComponent as DeliveryIcon } from 'assets/delivery-icon.svg';
import { TIMELINE_ENTITY_TYPES_ENUMS, USER_TYPES_ENUMS } from '../../../constants';

const OrderStatus = ({ status }) => {
  const { lang, translate, direction } = useContext(localeContext);
  const { colors } = useTheme();
  const isArabic = lang === 'ar';
  const { state, entityType, assignee, assigneeAr, partnerError, actionBy, userType } = status || {};

  const entityTypeText =
    entityType === TIMELINE_ENTITY_TYPES_ENUMS.ORDER_TYPE ? (
      <Typography variant="heading16">{translate(translations.TIMELINE_ORDER_TYPE)}</Typography>
    ) : (
      <Typography variant="heading16">{translate(translations.TIMELINE_DELIVERY_TYPE)}</Typography>
    );

  const getOrderStatusText = orderStatus => (
    <div className={cx('flex flex-wrap gap-1', isArabic && 'flex-row-reverse justify-end')}>
      {entityTypeText}
      <Typography variant="heading16">{translate(orderStatus)}</Typography>
      {partnerError && <Typography variant="heading16">: {partnerError}</Typography>}
    </div>
  );

  const getAssignedOrderText = orderStatus => (
    <div className="flex flex-wrap gap-1">
      <Typography variant="heading16">{translate(orderStatus)}</Typography>
      <Typography variant="heading16">{isArabic ? assigneeAr : assignee}</Typography>
    </div>
  );

  const getOrderStatusDetails = orderStatus => {
    switch (orderStatus) {
      case ORDER_STATUS.ACCEPTED:
        return getOrderStatusText(translations.ACCEPTED_ORDERS);
      case ORDER_STATUS.ASSIGNED:
        return getAssignedOrderText(translations.ASSIGNED_TO);
      case ORDER_STATUS.CANCELED:
        return getOrderStatusText(translations.CANCELED_ORDERS);
      case ORDER_STATUS.DECLINED:
        return getOrderStatusText(translations.DECLINED_ORDERS);
      case ORDER_STATUS.DELIVERED:
        return getOrderStatusText(translations.DELIVERED_ORDERS);
      case ORDER_STATUS.PUSHED:
        return getOrderStatusText(translations.PUSHED_ORDERS);
      case ORDER_STATUS.PUSH_FAILED:
        return getOrderStatusText(translations.PUSH_FAILED_ORDERS);
      case ORDER_STATUS.DISPATCHED:
        return getOrderStatusText(translations.DISPATCHED_ORDERS);
      case ORDER_STATUS.SUBMITTED:
        return getOrderStatusText(translations.SUBMITTED_ORDERS);
      case ORDER_STATUS.READY:
        return getOrderStatusText(translations.READY_ORDERS);
      case ORDER_STATUS.FULFILLED:
        return getOrderStatusText(translations.FULFILLED_ORDERS);
      case ORDER_STATUS.MANUALLY_ORDERED:
        return getOrderStatusText(translations.MANUALLY_ORDERED);
      default:
        return '';
    }
  };

  return (
    <div className="flex gap-2 items-center" style={{ direction }}>
      {userType === USER_TYPES_ENUMS.DRIVER || userType === USER_TYPES_ENUMS.COURIER ? (
        <DeliveryIcon fill={colors.positive.tertiary} />
      ) : (
        <CheckCircleIcon width="20px" color={colors.positive.tertiary} />
      )}
      <div className="flex flex-col">
        {getOrderStatusDetails(state)}
        {actionBy && (
          <Typography variant="element14" color={colors.positive.secondary}>
            {translate(ACTION_BY, lang, actionBy)}
          </Typography>
        )}
      </div>
    </div>
  );
};

OrderStatus.propTypes = {
  status: PropTypes.objectOf({
    state: PropTypes.oneOf([
      ORDER_STATUS.ACCEPTED,
      ORDER_STATUS.CANCELED,
      ORDER_STATUS.DELIVERED,
      ORDER_STATUS.DISPATCHED,
      ORDER_STATUS.SUBMITTED,
      ORDER_STATUS.READY,
      ORDER_STATUS.FULFILLED,
      ORDER_STATUS.ASSIGNED,
      ORDER_STATUS.DECLINED,
    ]),
    entityType: PropTypes.oneOf([
      TIMELINE_ENTITY_TYPES_ENUMS.ORDER_TYPE,
      TIMELINE_ENTITY_TYPES_ENUMS.DELIVERY_REQUEST_TYPE,
    ]),
    assignee: PropTypes.string,
    assigneeAr: PropTypes.string,
    partnerError: PropTypes.string,
  }),
};

export default OrderStatus;
