import React, { useContext } from 'react';
import { Typography, useTheme } from '@zydalabs/zac-react';
import { InfoIcon } from '@zydalabs/zac-icons-react';

import { context as userContext } from 'context/user';
import { context as localeContext } from 'context/locale';
import {
  CURRENT_TEMPLATE_ONE_TITLE,
  CURRENT_TEMPLATE_TWO_TITLE,
  CURRENT_TEMPLATE_THREE_TITLE,
  SOMETHING_WENT_WRONG,
  KWD,
  ENGAGE_FEES_ACTIVATED_MSG,
  ENGAGE_FEES_APPLY,
} from 'constants/translations';
import { useFetchCurrentEngageSettings } from 'service/hooks/engage';
import Spinner from 'components/kit/Uploader/Spinner';
import { Text } from 'components/service';
import { engageTypes } from '../../utils';

const CurrentSettingModal = () => {
  const { direction, translate } = useContext(localeContext);
  const { colors } = useTheme();
  const { selectedStore } = useContext(userContext);
  const { data, isLoading, error } = useFetchCurrentEngageSettings({
    subdomain: selectedStore.subdomain,
  });

  // handle loading state
  if (isLoading) {
    return (
      <div className="p-5 flex items-center justify-center">
        <Spinner />
      </div>
    );
  }

  // handle error state
  if (error) {
    return <Typography variant="body14">{translate(SOMETHING_WENT_WRONG)}</Typography>;
  }
  const { currentEngageSettings } = data;
  const { engageFees } = currentEngageSettings;

  const renderAmount = setting => {
    if (currentEngageSettings[setting].amount) {
      if (
        currentEngageSettings[setting].type === engageTypes.CASHBCK ||
        currentEngageSettings[setting].type === engageTypes.PERCENTAGE
      ) {
        return `(${currentEngageSettings[setting].amount}%)`;
      }
      if (currentEngageSettings[setting].type === engageTypes.AMOUNT) {
        return `(${currentEngageSettings[setting].amount} ${translate(KWD)})`;
      }
      return `(${currentEngageSettings[setting].amount})`;
    }
    return null;
  };

  return (
    <>
      <ul>
        <li className="mb-4 pb-4 border-b border-gray-200">
          <Typography variant="heading14" mb={12} color={colors.gray[600]}>
            {translate(CURRENT_TEMPLATE_ONE_TITLE)}
          </Typography>

          <div className="flex items-center mb-1">
            <Typography variant="heading14">
              {translate([
                currentEngageSettings.engage1Settings.titleEn,
                currentEngageSettings.engage1Settings.titleAr,
              ])}
            </Typography>
            <div className="mx-2">
              <Typography variant="heading14">{renderAmount('engage1Settings')}</Typography>
            </div>
          </div>
          <Typography variant="body14" color={colors.gray[600]}>
            {translate([
              currentEngageSettings.engage1Settings.descriptionEn,
              currentEngageSettings.engage1Settings.descriptionAr,
            ])}
          </Typography>
        </li>

        <li className="mb-4 pb-4 border-b border-gray-200">
          <Typography variant="heading14" mb={12} color={colors.gray[600]}>
            {translate(CURRENT_TEMPLATE_TWO_TITLE)}
          </Typography>

          <div className="flex items-center mb-1">
            <Typography variant="heading14">
              {translate([
                currentEngageSettings.engage2Settings.titleEn,
                currentEngageSettings.engage2Settings.titleAr,
              ])}
            </Typography>
            <div className="mx-2">
              <Typography variant="heading14">{renderAmount('engage2Settings')}</Typography>
            </div>
          </div>
          <Typography variant="body14" color={colors.gray[600]}>
            {translate([
              currentEngageSettings.engage2Settings.descriptionEn,
              currentEngageSettings.engage2Settings.descriptionAr,
            ])}
          </Typography>
        </li>

        <li className="mb-2">
          <Typography variant="heading14" mb={12} color={colors.gray[600]}>
            {translate(CURRENT_TEMPLATE_THREE_TITLE)}
          </Typography>

          <div className="flex items-center mb-1">
            <Typography variant="heading14">
              {translate([
                currentEngageSettings.engage3Settings.titleEn,
                currentEngageSettings.engage3Settings.titleAr,
              ])}
            </Typography>
            <div className="mx-2">
              <Typography variant="heading14">
                <Typography variant="heading14">{renderAmount('engage3Settings')}</Typography>
              </Typography>
            </div>
          </div>
          <Typography variant="body14" color={colors.gray[600]}>
            {translate([
              currentEngageSettings.engage3Settings.descriptionEn,
              currentEngageSettings.engage3Settings.descriptionAr,
            ])}
          </Typography>
        </li>
      </ul>
      <div className="p-3 mt-5 mb-4 border border-primary-200 bg-primary-50 flex items-start" style={{ direction }}>
        <div className="flex-shrink-0">
          <InfoIcon color="#196eff" width="15px" />
        </div>

        <div className="mx-2">
          <Typography variant="body12">
            <strong className="text-primary-base">{translate(ENGAGE_FEES_APPLY)}</strong>
            <Text className="inline" value={ENGAGE_FEES_ACTIVATED_MSG} payload={engageFees} />
          </Typography>
        </div>
      </div>
    </>
  );
};

export default CurrentSettingModal;
