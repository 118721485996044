import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import moment from 'moment';
import { InfoIcon } from '@zydalabs/zac-icons-react';
import { Spinner, useTheme } from '@zydalabs/zac-react';

import { ReactComponent as CheckCircle } from 'assets/check-circle.svg';
import { ReactComponent as Repeat } from 'assets/repeat.svg';
import { ReactComponent as VerdIcon } from 'assets/verd-icon.svg';
import { ReactComponent as DeliveryIcon } from 'assets/delivery-icon.svg';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import { ORDER_STATUS } from 'constants/order';
import Slideover from 'components/common/dashboard/Layout/Slideover/Slideover';
import { Modal, Button, ShimmerCircle } from 'components/kit';
import * as translations from 'constants/translations';
import { useAssignScheduledOrderNow, useFetchVerdDrivers } from 'service/hooks';
import updateDeliveryCourierCache from 'utils/updatingCache/updateDeliveryCourierCache';
import { useMobile, useSelectedStore } from 'hooks';
import { ORDER_RIDER_STATUS } from 'constants/orderRiderStatus';
import AddRiderModal from '../OrderDetails/verdSection/AddRiderModal';
import useHandleAssignUnAssignDriverToOrder from '../utils/handleAssignDrivertoOrder';
import AssignDeliveryModal from '../OrderDetails/verdSection/AssignDeliveryModal';
import useHandleAssignUnAssignCourierToOrder from '../utils/handleAssignCourierToOrder';
import useGetCourierInfoWithLogo from '../utils/useGetCourierInfoWithLogo';

const DeliveryActionButtons = ({
  order,
  isUpdatingStatus,
  shouldDisableActionBtns,
  sendChangeStatus,
  fromOrderDetails,
}) => {
  const {
    status: orderStatus,
    branchId,
    deliveryStatus,
    isScheduled: isScheduledOrder,
    deliveryCourierId,
    number,
    driverId,
    paymentStatus,
    firingTime,
  } = order || {};
  const { lang, translate } = useContext(localeContext);
  const isMobile = useMobile();
  const { settings, selectedStore } = useContext(userContext);
  const { restaurantCourierSetting, timeZone } = selectedStore || {};
  const { user } = useContext(userContext);
  const assignScheduledOrderNow = useAssignScheduledOrderNow();
  const selectedStoreId = useSelectedStore();
  const { data: activeRiders, isLoading: isVerdRidersLoading, mutate: mutateFetchVerdDrivers } = useFetchVerdDrivers({
    storeId: selectedStoreId,
    branchId,
  });
  const [isDeliverySlideOverOpen, setIsDeliverySlideOverOpen] = useState(false);
  const { colors } = useTheme();

  const isArabic = lang === 'ar';
  const isVerdEnabled = settings?.enableVerd;
  const verd = restaurantCourierSetting?.find(courier => courier.isInternalDelivery);

  const [shouldShowRiderModal, setShouldShowRiderModal] = useState(verd?.showRiderModal || false);

  const riders = activeRiders?.length ? activeRiders.filter(rider => rider.onShift) : [];
  const isPendingScheduledOrder = isScheduledOrder && deliveryStatus === ORDER_RIDER_STATUS.PENDING;

  const { handleAssignCourierToOrder, isCourierInfoLoading } = useHandleAssignUnAssignCourierToOrder({
    order,
    mutateFetchVerdDrivers,
  });

  const { handleAssignDriverToOrder, isRiderInfoLoading } = useHandleAssignUnAssignDriverToOrder({
    order,
    mutateFetchVerdDrivers,
  });

  const {
    restaurantCouriersWithLogos,
    isCouriersEstimationsLoading,
    courierEstimationsError,
  } = useGetCourierInfoWithLogo(order);

  const assignedDriver = activeRiders?.find(driver => parseInt(driver.id) === driverId);
  const isOrderDeliveryCourierNotVerd = !assignedDriver;
  const assignedCourier =
    isOrderDeliveryCourierNotVerd &&
    restaurantCouriersWithLogos?.find(courier => Number(courier.courierId) === Number(deliveryCourierId));
  const hasCourierInfo = deliveryCourierId && isOrderDeliveryCourierNotVerd;
  const hasRiders = riders?.length !== 0;

  const isOrderAssignedToCourierOrRider =
    (!!assignedDriver?.id || !!assignedCourier?.courierId) &&
    !!deliveryStatus &&
    ![ORDER_RIDER_STATUS.CANCELED].includes(deliveryStatus);
  const quickAssignCourierId = deliveryCourierId;

  const shouldOpenAddRiderModal = !(hasRiders || hasCourierInfo) && shouldShowRiderModal;
  const shouldOpenAssignDeliveryModal =
    !isOrderAssignedToCourierOrRider &&
    (restaurantCouriersWithLogos?.length > 0 || (isVerdEnabled && riders?.length !== 0));

  const courierAssigneeName = isArabic
    ? assignedCourier?.courierDetails?.displayNameAr || assignedCourier?.courierDetails?.name
    : assignedCourier?.courierDetails?.displayNameEn || assignedCourier?.courierDetails?.name;

  const shouldShowDeliveryNowButton =
    isPendingScheduledOrder && isOrderDeliveryCourierNotVerd && isOrderAssignedToCourierOrRider;

  const firingTimeFormatted = moment
    .tz(firingTime, timeZone)
    .locale(lang === 'ar' ? 'ar' : 'en-gb')
    .format('h:mm A, DD MMM');

  const getCourierOrDeliveryIcon = colorFill =>
    isOrderDeliveryCourierNotVerd ? (
      <div className="w-6 h-6 flex">
        {!assignedCourier?.logoUrl ? (
          <ShimmerCircle />
        ) : (
          <img className="rounded-full shadow-md" src={assignedCourier?.logoUrl} alt="courierLogo" />
        )}
      </div>
    ) : (
      <DeliveryIcon fill={colorFill} />
    );

  const ShowNextStatus = ({ open, close, closeable }) => {
    if (
      orderStatus === ORDER_STATUS.SUBMITTED &&
      !isOrderAssignedToCourierOrRider &&
      deliveryStatus !== ORDER_RIDER_STATUS.PENDING
    )
      return (
        <Button
          full
          isRounded
          kind="primary"
          isSpinning={isUpdatingStatus}
          disabled={shouldDisableActionBtns}
          icon={<CheckCircle />}
          onClick={() => {
            if (shouldOpenAddRiderModal)
              open({
                body: (
                  <div onClick={e => e.stopPropagation()} aria-hidden="true">
                    <AddRiderModal
                      close={close}
                      closeable={closeable}
                      mutateFetchVerdDrivers={mutateFetchVerdDrivers}
                      sendChangeStatus={sendChangeStatus}
                      handleAssignDriverToOrder={handleAssignDriverToOrder}
                      setShouldShowRiderModal={setShouldShowRiderModal}
                    />
                  </div>
                ),
              });
            else if (shouldOpenAssignDeliveryModal) {
              setIsDeliverySlideOverOpen(true);
              sendChangeStatus({ status: ORDER_STATUS.ACCEPTED, sendCourierId: false });
            } else sendChangeStatus({ status: ORDER_STATUS.ACCEPTED, sendCourierId: false });
          }}
          data-testid="accept-order-button"
        >
          {translate(translations.ACCEPT_ORDER)}
        </Button>
      );

    switch (deliveryStatus) {
      case ORDER_RIDER_STATUS.CANCELED:
      case null:
        return (
          <Button
            full
            isRounded
            size="md"
            kind="primary"
            icon={<VerdIcon fill="white" />}
            style={{
              backgroundColor: colors.green.primary,
              color: colors.white,
            }}
            isSpinning={isRiderInfoLoading || isCourierInfoLoading}
            disabled={shouldDisableActionBtns}
            onClick={() => {
              setIsDeliverySlideOverOpen(true);
            }}
            data-testid="assign-delivery-button"
          >
            {translate(translations.ASSIGN_DELIVERY)}
          </Button>
        );
      case ORDER_RIDER_STATUS.PENDING:
        return (
          <Button
            full
            isRounded
            size="md"
            kind="primary"
            cursor="default"
            icon={getCourierOrDeliveryIcon(colors.orange[700])}
            style={{
              backgroundColor: colors.orange[200],
              color: colors.orange[700],
              border: 'none',
            }}
            isSpinning={isRiderInfoLoading || isCourierInfoLoading}
            data-testid="ready-order-button"
          >
            {shouldShowDeliveryNowButton
              ? translate(translations.SCHEDULED_AT_FIRING_TIME, lang, firingTimeFormatted)
              : translate(
                  translations.PENDING_ACCEPTANCE_BY,
                  lang,
                  isOrderDeliveryCourierNotVerd ? courierAssigneeName : assignedDriver?.name,
                )}
          </Button>
        );
      case ORDER_RIDER_STATUS.ACCEPTED:
        return (
          <Button
            full
            isRounded
            size="md"
            kind="primary"
            cursor="default"
            icon={getCourierOrDeliveryIcon(colors.green[700])}
            isSpinning={isRiderInfoLoading || isCourierInfoLoading}
            style={{
              backgroundColor: colors.green[200],
              color: colors.green[700],
              border: 'none',
            }}
            data-testid="ready-order-button"
          >
            {isOrderDeliveryCourierNotVerd
              ? translate(translations.COURIER_EN_ROUTE_PICKUP, lang, courierAssigneeName)
              : translate(translations.DRIVER_ACCEPTED, lang, assignedDriver?.name)}
          </Button>
        );
      case ORDER_RIDER_STATUS.DISPATCHED:
        return (
          <Button
            full
            isRounded
            size="md"
            kind="primary"
            cursor="default"
            icon={getCourierOrDeliveryIcon(colors.green[700])}
            isSpinning={isRiderInfoLoading || isCourierInfoLoading}
            style={{
              backgroundColor: colors.green[200],
              color: colors.green[700],
              border: 'none',
            }}
            data-testid="Dispatch-order-button"
          >
            {translate(
              translations.OUT_FOR_DELIVERY,
              lang,
              isOrderDeliveryCourierNotVerd ? courierAssigneeName : assignedDriver?.name,
            )}
          </Button>
        );
      case ORDER_RIDER_STATUS.DECLINED:
        return (
          <div className="w-full flex justify-between gap-2">
            <Button
              full
              size="md"
              isRounded
              kind="primary"
              cursor="default"
              icon={<InfoIcon width="20px" color={colors.red[700]} />}
              style={{
                backgroundColor: colors.red[200],
                color: colors.red[700],
                border: 'none',
              }}
              data-testid="ready-order-button"
            >
              {translate(
                translations.COURIER_DECLINED_REQUEST,
                lang,
                isOrderDeliveryCourierNotVerd ? courierAssigneeName : assignedDriver?.name,
              )}
            </Button>
            <Button
              size="md"
              isRounded
              kind="primary"
              icon={<Repeat />}
              style={{
                backgroundColor: colors.green.primary,
                color: colors.white,
              }}
              isSpinning={isRiderInfoLoading || isCourierInfoLoading}
              disabled={shouldDisableActionBtns}
              onClick={() => {
                setIsDeliverySlideOverOpen(true);
              }}
              data-testid="assign-delivery-button"
            >
              {translate(translations.REASSIGN)}
            </Button>
          </div>
        );
      default:
        return null;
    }
  };

  const AssignScheduledNow = () => (
    <Button
      isRounded
      full={isMobile}
      size="md"
      kind="primary"
      icon={<CheckCircle />}
      isSpinning={isUpdatingStatus}
      disabled={shouldDisableActionBtns}
      onClick={() => {
        assignScheduledOrderNow({
          orderNumber: number,
          userId: user?.id,
        });
        const orderVariables = {
          orderId: order.id,
          storeId: selectedStoreId,
        };
        const updatedOrder = {
          ...order,
          deliveryStatus: ORDER_RIDER_STATUS.ACCEPTED,
        };
        updateDeliveryCourierCache(orderVariables, updatedOrder);
      }}
      data-testid="assign-now-button"
    >
      {translate(translations.DELIVER_ORDER)}
    </Button>
  );

  if (
    (isOrderDeliveryCourierNotVerd && isCouriersEstimationsLoading && !courierEstimationsError) ||
    isVerdRidersLoading
  ) {
    return <Spinner />;
  }

  return (
    <>
      {!(
        [ORDER_STATUS.DELIVERED, ORDER_STATUS.CANCELED].includes(orderStatus) ||
        (orderStatus === ORDER_STATUS.PENDING &&
          [ORDER_STATUS.PAYMENT_FAILED, ORDER_STATUS.WAITING_FOR_PAYMENT, ORDER_STATUS.PAYMENT_EXPIRED].includes(
            paymentStatus,
          ))
      ) && (
        <Modal>
          {({ open, close, closeable }) => (
            <div
              className={cx('flex justify-end', !fromOrderDetails && 'w-full')}
              onClick={e => e.stopPropagation()}
              aria-hidden="true"
            >
              {isPendingScheduledOrder && isOrderDeliveryCourierNotVerd && isOrderAssignedToCourierOrRider ? (
                <div
                  className={cx(
                    isMobile ? 'flex flex-col w-full' : 'flex flex-row justify-between gap-2',
                    !fromOrderDetails && 'w-full',
                  )}
                >
                  <ShowNextStatus open={open} close={close} closeable={closeable} />
                  <div className={cx(isMobile && 'mt-3')}>
                    <AssignScheduledNow />
                  </div>
                </div>
              ) : (
                <ShowNextStatus open={open} close={close} closeable={closeable} />
              )}
              <Slideover
                isOpen={isDeliverySlideOverOpen}
                fullWidth={isMobile}
                body={
                  <AssignDeliveryModal
                    order={order}
                    mutateFetchVerdDrivers={mutateFetchVerdDrivers}
                    close={() => setIsDeliverySlideOverOpen(false)}
                    handleAssignDriverToOrder={handleAssignDriverToOrder}
                    handleAssignCourierToOrder={handleAssignCourierToOrder}
                    assigned={
                      !!deliveryStatus &&
                      deliveryStatus !== ORDER_RIDER_STATUS.CANCELED &&
                      (assignedDriver || assignedCourier)
                    }
                    restaurantCouriersWithLogos={restaurantCouriersWithLogos}
                    riders={riders}
                    quickAssignCourierId={quickAssignCourierId}
                    sendChangeStatus={sendChangeStatus}
                    isCouriersEstimationsLoading={isCouriersEstimationsLoading && !courierEstimationsError}
                  />
                }
              />
            </div>
          )}
        </Modal>
      )}
    </>
  );
};

DeliveryActionButtons.propTypes = {
  order: PropTypes.shape({
    status: PropTypes.string.isRequired,
    deliveryStatus: PropTypes.string,
    isScheduled: PropTypes.bool,
    deliveryCourierId: PropTypes.number,
    number: PropTypes.string,
    deliveryCourier: PropTypes.shape({
      isInternalDelivery: PropTypes.bool,
      courierId: PropTypes.number,
      driverPhoneNumber: PropTypes.string,
    }),
  }),
  isUpdatingStatus: PropTypes.bool,
  shouldDisableActionBtns: PropTypes.bool,
  sendChangeStatus: PropTypes.func.isRequired,
};
export default DeliveryActionButtons;
