import React, { useContext } from 'react';
import cx from 'classnames';
import { Typography, useTheme, Button } from '@zydalabs/zac-react';
import { ExternalLinkIcon, GridSquare3X3Icon } from '@zydalabs/zac-icons-react';
import moment from 'moment';

import * as translations from 'constants/translations';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import { SpinnerAlt, ShimmerCircle } from 'components/kit';
import { ORDER_STATUS } from 'constants/order';
import { ReactComponent as DeliveryIcon } from 'assets/delivery-icon.svg';
import { ReactComponent as VerdIcon } from 'assets/verd-icon.svg';
import { ORDER_RIDER_STATUS } from 'constants';
import { EXTERNAL_ORDER_IDENTIFIER_TYPE } from '../../../constants';

const StoreCourierInfo = ({
  order,
  assignedCourier,
  reassignButton,
  isOrderDeliveryCourierNotVerd,
  isOrderAssignedToCourierOrRider,
}) => {
  const { translate, lang } = useContext(localeContext);
  const { isDeliveryCourier, selectedStore } = useContext(userContext);
  const { colors } = useTheme();

  const { currency, timeZone } = selectedStore;
  const { status: orderStatus, deliveryStatus, deliveryCourier, verdFees, firingTime, isScheduled: isScheduledOrder } =
    order || {};
  const {
    externalOrderIdentifierLink,
    externalOrderIdentifierType,
    driverAssigned,
    driverName,
    trackingLink,
    driverPhoneNumber: orderDeliveryPhoneNumber,
  } = deliveryCourier || {};
  const deliveryFees = assignedCourier?.courierEstimations?.deliveryFees;
  const shouldShowQrCodeButton =
    isDeliveryCourier &&
    externalOrderIdentifierLink &&
    orderStatus !== ORDER_STATUS.CANCELED &&
    externalOrderIdentifierType?.toLowerCase() === EXTERNAL_ORDER_IDENTIFIER_TYPE.QRCODE;

  const isOrderScheduled =
    isScheduledOrder &&
    deliveryStatus === ORDER_RIDER_STATUS.PENDING &&
    isOrderDeliveryCourierNotVerd &&
    isOrderAssignedToCourierOrRider;

  const firingTimeFormatted = moment
    .tz(firingTime, timeZone)
    .locale(lang === 'ar' ? 'ar' : 'en-gb')
    .format('h:mm A, DD MMM');

  return (
    <div className="flex flex-col w-full border-t border-gray-200 mt-2 pt-4">
      <div className="w-full flex justify-between items-center">
        {deliveryStatus !== ORDER_RIDER_STATUS.DECLINED &&
          (driverAssigned ? (
            !!driverName && (
              <div className="flex items-center gap-2">
                <DeliveryIcon fill="black" />
                <div className="flex flex-col">
                  <Typography variant="element16">{driverName}</Typography>
                  <Typography variant="element14" color={colors.positive.secondary}>
                    {orderDeliveryPhoneNumber || ''}
                  </Typography>
                </div>
              </div>
            )
          ) : isOrderScheduled ? (
            <Typography>{translate(translations.SCHEDULED_AT_FIRING_TIME, lang, firingTimeFormatted)}</Typography>
          ) : (
            <div className="w-full flex items-center gap-2">
              <div className="w-6 h-6 flex items-center justify-center">
                <SpinnerAlt color="primary-base" />
              </div>
              <Typography>{translate(translations.SEARCHING_FOR_A_DRIVER)}</Typography>
            </div>
          ))}
        {reassignButton}
      </div>
      <div
        className={cx(
          'flex justify-between',
          (!!trackingLink || !!shouldShowQrCodeButton) && 'border-t border-gray-200 pt-4 mt-4',
        )}
      >
        {!!trackingLink && (
          <Button
            variant="ghost"
            size="small"
            onClick={() => window.open(trackingLink, '_blank')}
            startIcon={<ExternalLinkIcon width="20px" color={colors.accent.primary} />}
            text={translate(translations.TRACK_RIDER)}
          />
        )}
        {!!shouldShowQrCodeButton && (
          <Button
            variant="ghost"
            size="small"
            onClick={() => window.open(externalOrderIdentifierLink, '_blank')}
            startIcon={<GridSquare3X3Icon width="20px" color={colors.accent.primary} />}
            text={translate(translations.OPEN_QRCODE)}
          />
        )}
      </div>
      {!!deliveryFees && orderStatus === ORDER_STATUS.DELIVERED && (
        <div className={cx('w-full flex gap-2 border-t border-gray-200 pt-4 mt-4')}>
          <div className="w-6 h-6">
            {!assignedCourier?.logoUrl ? (
              <ShimmerCircle />
            ) : (
              <img className="rounded-full shadow-md" src={assignedCourier?.logoUrl} alt="courier logo" />
            )}
          </div>
          <div>
            <Typography>{`${translate(currency)} ${deliveryFees?.toFixed(currency.decimals)} `}</Typography>
            <Typography variant="element14" color={colors.positive.secondary}>
              {translate(translations.DELIVERY_FEE_DEDUCTED)}
            </Typography>
          </div>
        </div>
      )}
      {!!verdFees && (
        <div className="w-full flex gap-2 border-t border-gray-200 pt-4 mt-4">
          <div className="mt-1">
            <VerdIcon fill={colors.positive.secondary} />
          </div>
          <div>
            <Typography>{`${translate(currency)} ${verdFees?.toFixed(currency.decimals)} `}</Typography>
            <Typography variant="element14" color={colors.positive.secondary}>
              {translate(translations.PROCESSING_FEES)}
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
};

export default StoreCourierInfo;
