import React, { useContext } from 'react';
import moment from 'moment';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { SCHEDULED, AS_SOON_AS_POSSIBLE, CITY, AREA, ZONE } from 'constants/translations';
import { getDeliveryTypeText } from 'utils/getDeliveryTypeText';
import { context as userContext } from 'context/user';
import { COUNTRY_CODES_ENUM } from 'utils/enums';
import DELIVERY_TYPE_ENUM from '../A4OrderInvoice/utils';

const ThermalInvoiceInfo = ({ lang, direction, translate, order, store, theTimeSlot }) => {
  const { deliveryType, isScheduled, expectedAt, branchData, branchName, userData, deliveryZone } = order || {};
  const {
    selectedStore: { countryCode },
  } = useContext(userContext);
  const isKuwait = countryCode === COUNTRY_CODES_ENUM.KUWAIT;
  return (
    <div>
      <div
        className={cx(
          'flex justify-between p-1 border-dashed border-gray-300 border-t text-xs font-bold',
          lang === 'ar' && 'flex-row-reverse',
        )}
      >
        <div className={cx('flex w-1/2 font-bold', lang === 'ar' && 'flex-row-reverse')}>
          <span style={{ direction }}>{getDeliveryTypeText(deliveryType, translate)}</span>
        </div>
        <div className="flex flex-col w-1/2 font-bold" style={{ direction }}>
          <span>{isScheduled ? translate(SCHEDULED) : translate(AS_SOON_AS_POSSIBLE)}</span>
          <span>
            {theTimeSlot
              ? `${theTimeSlot.toString()}, ${moment(expectedAt)
                  .tz(store.timeZone)
                  .locale(lang === 'ar' ? 'ar' : 'en-gb')
                  .format('DD MMM YYYY')}`
              : moment(expectedAt)
                  .tz(store.timeZone)
                  .locale(lang === 'ar' ? 'ar' : 'en-gb')
                  .format('DD MMM YYYY, h:mm A')}
          </span>
        </div>
      </div>
      <div
        className={cx(
          'flex justify-between p-1 border-dashed border-gray-300 border-t text-xs font-bold',
          lang === 'ar' && 'flex-row-reverse',
        )}
      >
        <div className="flex">
          <span style={{ direction }}>{branchData ? translate(branchData) : branchName}</span>
        </div>
      </div>
      {deliveryType === DELIVERY_TYPE_ENUM.DELIVERY && (
        <>
          {// checking if store use new delivery zone system (useDeliveryzonesMs)
          // NOTE later we will migrate all stores to new system
          deliveryZone?.zoneName && (
            <div
              className={cx(
                'flex justify-between p-1 border-dashed border-gray-300 border-t text-xs font-bold',
                lang === 'ar' && 'flex-row-reverse',
              )}
            >
              <div className={cx('flex', lang === 'ar' && 'flex-row-reverse')}>
                <span style={{ direction }}>{translate(ZONE)}:</span>
                <span style={{ direction }} className="px-1">
                  {deliveryZone.zoneName}
                </span>
              </div>
            </div>
          )}

          {deliveryZone?.zoneName && userData.address.cityName && !isKuwait && (
            <div
              className={cx(
                'flex justify-between p-1 border-dashed border-gray-300 border-t text-xs font-bold',
                lang === 'ar' && 'flex-row-reverse',
              )}
            >
              <div className={cx('flex', lang === 'ar' && 'flex-row-reverse')}>
                <span style={{ direction }}>{translate(CITY)}:</span>
                <span style={{ direction }} className="px-1">
                  {userData.address.cityName}
                </span>
              </div>
            </div>
          )}
          {deliveryZone?.zoneName && userData.address.areaName && (
            <div
              className={cx(
                'flex justify-between p-1 border-dashed border-gray-300 border-t text-xs font-bold',
                lang === 'ar' && 'flex-row-reverse',
              )}
            >
              <div className={cx('flex', lang === 'ar' && 'flex-row-reverse')}>
                <span style={{ direction }}>{translate(AREA)}:</span>
                <span style={{ direction }} className="px-1">
                  {userData.address.areaName}
                </span>
              </div>
            </div>
          )}

          {!deliveryZone?.zoneName && (
            <>
              <div
                className={cx(
                  'flex justify-between p-1 border-dashed border-gray-300 border-t text-xs font-bold',
                  lang === 'ar' && 'flex-row-reverse',
                )}
              >
                <div className={cx('flex', lang === 'ar' && 'flex-row-reverse')}>
                  <span style={{ direction }}>{translate(AREA)}:</span>
                  <span style={{ direction }} className="px-1">
                    {translate(userData?.address?.area)}
                  </span>
                </div>
              </div>
              <div
                className={cx(
                  'flex justify-between p-1 border-dashed border-gray-300 border-t text-xs font-bold',
                  lang === 'ar' && 'flex-row-reverse',
                )}
              >
                <div className={cx('flex', lang === 'ar' && 'flex-row-reverse')}>
                  <span style={{ direction }}>{translate(CITY)}:</span>
                  <span style={{ direction }} className="px-1">
                    {lang === 'en' ? userData?.address?.area?.cityTitleEn : userData?.address?.area?.cityTitleAr}
                  </span>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

ThermalInvoiceInfo.propTypes = {
  lang: PropTypes.string,
  direction: PropTypes.string,
  order: PropTypes.shape({
    deliveryType: PropTypes.string,
    isScheduled: PropTypes.bool,
    expectedAt: PropTypes.string,
    branchData: PropTypes.string,
    branchName: PropTypes.string,
    userData: PropTypes.shape({
      address: PropTypes.shape({
        area: PropTypes.shape({ cityTitleEn: PropTypes.string, cityTitleAr: PropTypes.string }),
      }),
    }),
  }),
  translate: PropTypes.func,
  theTimeSlot: PropTypes.string,
  store: PropTypes.shape({ timeZone: PropTypes.string }),
};
export default ThermalInvoiceInfo;
