import React, { useContext, useRef, useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { CopyIcon, MapPinIcon } from '@zydalabs/zac-icons-react';
import { Typography, useTheme } from '@zydalabs/zac-react';

import { context as localeContext } from 'context/locale';
import * as translations from 'constants/translations';
import Address from './Address';
import { copyToClipboard } from '../../helpers';
import AddressMap from './AddressMap';

const OrderDetailsUserAddress = ({ order }) => {
  const addressRef = useRef();
  const { colors } = useTheme();
  const [addressCopied, setAddressCopied] = useState(false);

  const { lang, direction, translate } = useContext(localeContext);

  const mobileLargeMQ = useMediaQuery({ maxWidth: 425 });
  const mediumToLrgMQ = useMediaQuery({ minWidth: 426, maxWidth: 1024 });

  useEffect(() => {
    addressCopied && setTimeout(() => setAddressCopied(false), 3000);
  }, [addressCopied]);

  return (
    <div className={cx('flex flex-col w-full pt-4 mt-4 border-t border-gray-200')}>
      <div className="flex gap-2 items-start">
        <MapPinIcon width="24px" color="#c1c1c1" />
        <div>
          <span style={{ direction }} ref={addressRef}>
            <Address {...order?.userData?.address} {...order?.deliveryZone} />
          </span>
          {order?.userData?.address?.notes && (
            <div
              style={{ direction, backgroundColor: colors.blue[100] }}
              className="w-fit flex items-center mt-2 mb-3 px-2 py-1 bg-yellow-50 gap-2"
            >
              <Typography variant="heading14" color={colors.blue.primary}>
                {`${translate(translations.ORDER_NOTE)}:`}
              </Typography>
              <Typography variant="body14" color={colors.blue.primary}>
                {order?.userData?.address?.notes}
              </Typography>
            </div>
          )}
        </div>
      </div>
      <div
        className={cx(
          'flex items-center w-full mt-4 gap-7',
          mediumToLrgMQ && 'mt-1',
          mobileLargeMQ && 'w-full mt-1',
          (mobileLargeMQ || mediumToLrgMQ) && 'flex-wrap',
        )}
      >
        <div
          role="presentation"
          className="flex items-center cursor-pointer"
          onClick={() => {
            copyToClipboard(addressRef.current.innerText);
            setAddressCopied(true);
          }}
        >
          <CopyIcon
            width="20px"
            color={addressCopied ? colors.green.primary : colors.accent.primary}
            className={cx(lang === 'ar' ? 'ml-3' : 'mr-3')}
          />
          {addressCopied ? (
            <Typography color={colors.green.primary} cursor="pointer">
              {translate(translations.ADDRESS_COPIED)}
            </Typography>
          ) : (
            <Typography color={colors.accent.primary} cursor="pointer">
              {translate(translations.COPY)}
            </Typography>
          )}
        </div>
      </div>
      <AddressMap order={order} />
    </div>
  );
};

OrderDetailsUserAddress.propTypes = {
  order: PropTypes.shape({
    userData: PropTypes.shape({
      address: PropTypes.shape({
        area: PropTypes.shape({
          cityTitleEn: PropTypes.string,
          cityTitleAr: PropTypes.string,
        }),
        notes: PropTypes.string,
      }),
    }),
    deliveryZone: PropTypes.shape({
      zoneName: PropTypes.string,
    }),
  }),
  addressGMapURL: PropTypes.string,
  orderWhatsAppUrl: PropTypes.string,
};
export default OrderDetailsUserAddress;
