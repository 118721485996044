import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

import { context as notificationsContext } from 'context/notifications';
import { context as localeContext } from 'context/locale';
import {
  ACTIVATE_ENGAGE_MESSAGE,
  SOMETHING_WENT_WRONG,
  TEMPLATE_ONE_TITLE,
  TEMPLATE_ONE_TITLE_DESC,
  TEMPLATE_THREE_TITLE,
  TEMPLATE_THREE_TITLE_DESC,
  TEMPLATE_TWO_TITLE,
  TEMPLATE_TWO_TITLE_DESC,
} from 'constants/translations';
import { useCreateEngageSetting } from 'service/hooks/engage';
import { Text } from 'components/service';
import EngageStepView from './EngageStepView';
import { transformObject } from '../utils';
import EngageCurrentSteps from './EngageCurrentSteps';

const EngageStepsHeader = ({ title, subTitle }) => (
  <div>
    <Text className="text-sm mb-1 font-semibold" value={title} />
    <Text className="text-xs text-gray-500" value={subTitle} />
  </div>
);

const EngageSteps = ({ close, setEngageActive, headRef, transformedData, subdomain, engageFees }) => {
  const { translate, lang, direction } = useContext(localeContext);
  const notifications = useContext(notificationsContext);

  const [validatedSteps, setValidatedSteps] = useState([1]);
  const [steps, setSteps] = useState(1);
  const [disableActivateButton, setDisableActivateButton] = useState(false);
  const createEngageSetting = useCreateEngageSetting();

  const templateKeys = {
    id: '',
    staticId: '',
    hasVoucherPrecentage: false,
    voucherPrecentage: 0,
    hasVoucherAmount: false,
    voucherAmount: 0,
    hasCashback: false,
    cashback: 0,
  };
  const [formValues, setFormValues] = useState({
    engage1Settings: templateKeys,
    engage2Settings: templateKeys,
    engage3Settings: templateKeys,
  });

  const handleBack = number => {
    setSteps(number);
    setValidatedSteps(prevState => prevState.filter(step => step <= number));
    headRef.current.scrollIntoView();
  };

  const handleStepsClick = (values, setting, stepNumber) => {
    setFormValues(prevState => ({
      ...prevState,
      [setting]: values[setting],
    }));
    setSteps(stepNumber);
    setValidatedSteps(prevState => prevState.concat([stepNumber]));
    headRef.current.scrollIntoView();
  };

  const handleFinalSubmit = async values => {
    setFormValues(prevState => ({
      ...prevState,
      engage3Settings: values.engage3Settings,
    }));

    // get latest values
    const collectedFormValues = {
      ...formValues,
      engage3Settings: values.engage3Settings,
    };

    setDisableActivateButton(true);
    const response = await createEngageSetting({
      subdomain,
      enabled: true,
      setting: transformObject(collectedFormValues),
    });

    if (response.hasError) {
      setDisableActivateButton(false);
      notifications.show(<Text value={SOMETHING_WENT_WRONG} />, 'error');
    } else {
      setDisableActivateButton(false);
      setEngageActive(true);
      notifications.show(<Text value={ACTIVATE_ENGAGE_MESSAGE} />, 'success');
      close();
    }
  };

  return (
    <>
      <div className="header p-4 border-b border-gray-200" ref={headRef}>
        <EngageCurrentSteps direction={direction} steps={validatedSteps} />
        {steps === 1 && <EngageStepsHeader title={TEMPLATE_ONE_TITLE} subTitle={TEMPLATE_ONE_TITLE_DESC} />}
        {steps === 2 && <EngageStepsHeader title={TEMPLATE_TWO_TITLE} subTitle={TEMPLATE_TWO_TITLE_DESC} />}
        {steps === 3 && <EngageStepsHeader title={TEMPLATE_THREE_TITLE} subTitle={TEMPLATE_THREE_TITLE_DESC} />}
      </div>
      <div className="body p-4">
        <div className="mb-24">
          {steps === 1 && (
            <EngageStepView
              initialValues={{ engage1Settings: formValues.engage1Settings, step1: true }}
              onSubmit={values => handleStepsClick(values, 'engage1Settings', 2)}
              templateName="engage1Settings"
              transformedData={transformedData.engage1}
              templateKeys={templateKeys}
              translate={translate}
              lang={lang}
              engageFees={engageFees}
            />
          )}
          {steps === 2 && (
            <EngageStepView
              initialValues={{ engage2Settings: formValues.engage2Settings, step2: true }}
              onSubmit={values => handleStepsClick(values, 'engage2Settings', 3)}
              onBack={() => handleBack(1)}
              templateName="engage2Settings"
              transformedData={transformedData.engage2}
              templateKeys={templateKeys}
              translate={translate}
              lang={lang}
              engageFees={engageFees}
            />
          )}
          {steps === 3 && (
            <EngageStepView
              initialValues={{ engage3Settings: formValues.engage3Settings, step3: true }}
              onSubmit={handleFinalSubmit}
              onBack={() => handleBack(2)}
              templateName="engage3Settings"
              transformedData={transformedData.engage3}
              templateKeys={templateKeys}
              translate={translate}
              lang={lang}
              lastStep
              disableActivateButton={disableActivateButton}
              engageFees={engageFees}
            />
          )}
        </div>
      </div>
    </>
  );
};

EngageSteps.propTypes = {
  close: PropTypes.func.isRequired,
  setEngageActive: PropTypes.func.isRequired,
  headRef: PropTypes.shape({}),
  transformedData: PropTypes.shape({}),
  subdomain: PropTypes.string,
  engageFees: PropTypes.number,
};

export default EngageSteps;
