import React, { useContext } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Typography, useTheme } from '@zydalabs/zac-react';

import { context as localeContext } from 'context/locale';
import * as translations from 'constants/translations';
import { ReactComponent as ProductIcon } from 'assets/product-place-holder.svg';
import OrderDetailsCard from './OrderDetailsCard';

const OrderDetailsItems = ({ order, currency }) => {
  const { lang, translate, direction } = useContext(localeContext);
  const { colors } = useTheme();
  const { orderItems } = order || {};

  return (
    <div className="flex flex-col w-full border-t border-b border-gray-200 mt-2">
      {orderItems.map((item, i) => {
        const { quantity, imageUrl, variant, menuItem, totalPrice, properties, notes, id: menuItemId } = item || {};
        const menuItemTitle = [menuItem?.titleEn, menuItem?.titleAr];
        const variantTitles = [variant?.titleEn, variant?.titleAr];
        const quantityText = [`${quantity}x`, `x${quantity}`];

        return (
          <div key={menuItemId} className="flex flex-row w-full pt-4">
            <div className={cx('flex-2 w-12 h-12 mb-2', lang === 'ar' ? 'ml-2' : 'mr-2')}>
              {imageUrl ? (
                <img src={imageUrl} className="w-full rounded-lg h-full shadow-sm" alt="menu-item" />
              ) : (
                <ProductIcon />
              )}
            </div>
            <div className={cx('flex-1 flex flex-col border-gray-200', i < orderItems.length - 1 && 'border-b')}>
              <div className="flex-1 flex flex-row justify-between gap-2">
                <div className="flex gap-2">
                  <Typography variant="heading16">{translate(quantityText)}</Typography>
                  <Typography variant="heading16">{`${translate(menuItemTitle)} ${
                    variant ? `- ${translate(variantTitles)} ${variant?.barCode || ''}` : ''
                  } `}</Typography>
                </div>
                <div className="flex flex-shrink-0">
                  <Typography variant="element16">{`${translate(currency)} ${totalPrice.toFixed(
                    currency.decimals,
                  )}`}</Typography>
                </div>
              </div>
              {notes && notes.replace(/\s/g, '').length !== 0 && (
                <div
                  style={{ direction, backgroundColor: colors.orange[100] }}
                  className="w-fit flex items-center mt-2 mb-3 px-2 py-1 bg-yellow-50 gap-2"
                >
                  <Typography variant="heading14" color={colors.orange.primary}>
                    {`${translate(translations.ORDER_NOTE)}:`}
                  </Typography>
                  <Typography variant="body14" color={colors.orange.primary}>
                    {notes}
                  </Typography>
                </div>
              )}
              {properties?.length > 0 && (
                <div className="flex flex-col mt-4 gap-4">
                  {properties?.map(property => {
                    const propertyTitles = [property.titleEn, property.titleAr];
                    return (
                      <div key={property.id}>
                        <Typography variant="heading12" color={colors.gray[500]} marginBottom={8}>
                          {translate(propertyTitles)}
                        </Typography>
                        <div className="flex mb-3 gap-2 w-full">
                          <div className="w-1.5 bg-gray-200 rounded-full" />
                          <div className="gap-2 flex flex-col w-full">
                            {property.propertyValues.map(propertyValue => {
                              const propertyValueNames = [propertyValue.titleEn, propertyValue.titleAr];
                              return (
                                <OrderDetailsCard
                                  key={propertyValue.id}
                                  title={propertyTitles}
                                  quantity={propertyValue.quantity * quantity}
                                  itemQuantity={quantity}
                                  optionName={propertyValueNames}
                                  price={propertyValue.price}
                                  currency={currency}
                                  numberOfEach={propertyValue.quantity}
                                />
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

OrderDetailsItems.propTypes = {
  order: PropTypes.shape({
    orderItems: PropTypes.arrayOf({
      quantity: PropTypes.number,
      imageUrl: PropTypes.string,
      variant: PropTypes.shape({
        barCode: PropTypes.string,
        discountEnabled: PropTypes.bool.isRequired,
        discountedPrice: PropTypes.number,
        externalId: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        images: PropTypes.arrayOf(PropTypes.string).isRequired,
        prepTime: PropTypes.number.isRequired,
        price: PropTypes.number.isRequired,
        propertySections: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.object)),
        sku: PropTypes.string,
        titleAr: PropTypes.string.isRequired,
        titleEn: PropTypes.string.isRequired,
      }),
      menuItem: PropTypes.shape({ titleEn: PropTypes.string, titleAr: PropTypes.string }),
      totalPrice: PropTypes.number,
      propertyValues: PropTypes.shape({ quantity: PropTypes.number, price: PropTypes.number, id: PropTypes.string }),
      notes: PropTypes.string,
    }),
  }),
  currency: PropTypes.shape({
    decimals: PropTypes.number.isRequired,
    titleAr: PropTypes.string.isRequired,
    titleEn: PropTypes.string.isRequired,
  }),
};
export default OrderDetailsItems;
