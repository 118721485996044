import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { Button, Tooltip } from '@zydalabs/zac-react';
import { ArrowLeftIcon, ArrowRightIcon } from '@zydalabs/zac-icons-react';
import cx from 'classnames';

import { BACK, NEXT, SAVE, ENGAGE_FEES_MESSAGE, ENGAGE_FEES_TITLE } from 'constants/translations';
import { Text } from 'components/service';
import EngageOptionRadioGroup from '../../EngageOptionRadioGroup';
import { engageValidationSchema } from './data';

const EngageStepView = ({
  initialValues,
  onSubmit,
  onBack,
  templateName,
  transformedData,
  templateKeys,
  translate,
  lang,
  lastStep,
  disableActivateButton,
  engageFees,
}) => (
  <Formik initialValues={initialValues} onSubmit={values => onSubmit(values)} validationSchema={engageValidationSchema}>
    {({ values, setFieldValue, errors }) => (
      <Form>
        <EngageOptionRadioGroup
          items={transformedData}
          setFieldValue={setFieldValue}
          values={values}
          templateKeys={templateKeys}
          templateName={templateName}
          errors={errors}
          translate={translate}
        />

        <div className="my-4 flex items-center justify-center">
          <div className="flex items-center flex-shrink-0">
            <Text value={ENGAGE_FEES_TITLE} className="text-xs text-zyda-grey-60 mr-1" />
            <div className="flex-shrink-0">
              <Tooltip
                info={<Text className="w-64 text-center p-1" value={ENGAGE_FEES_MESSAGE} payload={engageFees} />}
                size="small"
                placement="bottom"
              />
            </div>
          </div>
        </div>

        <div className={cx('flex mt-8', onBack ? 'justify-between' : 'justify-end')}>
          {onBack && (
            <div className="w-32">
              <Button
                rounded
                isFluid
                variant="tertiary"
                text={translate(BACK)}
                onClick={onBack}
                startIcon={lang === 'ar' ? <ArrowRightIcon width="20px" /> : <ArrowLeftIcon width="20px" />}
              />
            </div>
          )}

          <div className="w-32">
            <Button
              rounded
              isFluid
              variant="primary"
              text={translate(lastStep ? SAVE : NEXT)}
              type="submit"
              onClick={() => {
                // prevent error
              }}
              endIcon={!lastStep && (lang === 'ar' ? <ArrowLeftIcon width="20px" /> : <ArrowRightIcon width="20px" />)}
              isLoading={disableActivateButton}
              isDisabled={disableActivateButton}
            />
          </div>
        </div>
      </Form>
    )}
  </Formik>
);

EngageStepView.propTypes = {
  transformedData: PropTypes.arrayOf(PropTypes.shape({})),
  templateKeys: PropTypes.shape({}),
  initialValues: PropTypes.shape({}),
  formValues: PropTypes.shape({
    engage1Settings: PropTypes.shape({}),
  }),
  onSubmit: PropTypes.func,
  onBack: PropTypes.func,
  templateName: PropTypes.string,
  translate: PropTypes.func,
  lang: PropTypes.string,
  lastStep: PropTypes.bool,
  disableActivateButton: PropTypes.bool,
  engageFees: PropTypes.number,
};

export default EngageStepView;
