import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Typography, useTheme } from '@zydalabs/zac-react';

import { context as localeContext } from 'context/locale';
import * as translations from 'constants/translations';

const OrderDetailsFees = ({ order, currency, deliveryType }) => {
  const { lang, translate } = useContext(localeContext);
  const { colors } = useTheme();
  const direction = lang === 'ar' ? 'rtl' : 'ltr';
  const {
    subtotal,
    discountedAmount,
    voucherCode,
    voucherAmount,
    subtotalAfterVoucher,
    taxPercentage,
    tax,
    deliveryFee,
    total,
    taxInclusive,
    cashbackAmount,
  } = order || {};

  const fees = [
    { id: 1, title: translate(translations.SUBTOTAL), amount: subtotal, cond: true },
    {
      id: 2,
      title: translate(translations.DISCOUNT),
      amount: discountedAmount,
      cond: discountedAmount !== 0,
      isNegative: true,
    },
    {
      id: 3,
      title: `${translate(translations.COUPON_DISCOUNT)} ${voucherCode}`,
      amount: voucherAmount,
      cond: voucherCode,
      isNegative: true,
      color: colors.red[500],
    },
    { id: 4, title: translate(translations.SUBTOTAL_AFTER_VOUCHER), amount: subtotalAfterVoucher, cond: voucherCode },
    { id: 5, title: `${translate(translations.TAX)} ${taxPercentage}%`, amount: tax, cond: !taxInclusive },
    { id: 6, title: translate(translations.DELIVERY_FEES), amount: deliveryFee, cond: deliveryType !== 'pickup' },
    {
      id: 7,
      title: translate(translations.CASHBACK_AMOUNT),
      amount: cashbackAmount,
      cond: cashbackAmount !== 0 && cashbackAmount > 0,
    },
    { id: 8, title: translate(translations.TAX_ARE_INCLUDED, lang, taxPercentage), amount: tax, cond: taxInclusive },
  ];
  return (
    <div className="w-full" style={{ direction }}>
      <div className="flex flex-col w-full py-3 border-b border-gray-200 gap-2">
        {fees.map(
          fee =>
            !!fee.cond && (
              <div key={fee.id} className="flex flex-row w-full justify-between gap-2 items-center">
                <Typography color={fee.color || ''}>{fee.title}</Typography>
                <div className="flex flex-shrink-0">
                  <Typography color={fee.color || ''}>{`${fee.isNegative ? '-' : ''} ${translate(
                    currency,
                  )} ${fee.amount.toFixed(currency.decimals)}`}</Typography>
                </div>
              </div>
            ),
        )}
      </div>
      <div className="flex flex-flex w-full justify-between pt-3">
        <Typography variant="heading16">{translate(translations.TOTAL)}</Typography>
        <Typography variant="heading16">{`${translate(currency)} ${total.toFixed(currency.decimals)}`}</Typography>
      </div>
    </div>
  );
};

OrderDetailsFees.propTypes = {
  order: PropTypes.shape({
    subtotal: PropTypes.number.isRequired,
    discountedAmount: PropTypes.number,
    voucherCode: PropTypes.string,
    voucherAmount: PropTypes.number,
    subtotalAfterVoucher: PropTypes.number.isRequired,
    taxPercentage: PropTypes.number.isRequired,
    tax: PropTypes.number.isRequired,
    taxInclusive: PropTypes.bool.isRequired,
    deliveryFee: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    verdFees: PropTypes.number.isRequired,
    feastFees: PropTypes.number.isRequired,
    engageFees: PropTypes.number.isRequired,
  }),
  currency: PropTypes.shape({
    decimals: PropTypes.number.isRequired,
    titleAr: PropTypes.string.isRequired,
    titleEn: PropTypes.string.isRequired,
  }),
  deliveryType: PropTypes.string,
};
export default OrderDetailsFees;
